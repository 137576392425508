import type { SelectChangeEvent } from '@mui/material';
import {
  Typography, Link as MuiLink, CircularProgress, Backdrop, Button, FormControl,
  Select, InputLabel, MenuItem, Divider, IconButton, Tooltip,
} from '@mui/material';
import { Box } from '@mui/material';
import { Stack } from '@mui/material';
import { Grid2 } from '@mui/material';
import React, { useMemo, useState } from 'react';
import type { CellContext, ColumnDef, SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { Autorenew } from '@mui/icons-material';
import GenericTable from '../table/GenericTable.js';
import type { CompleteSalesOrderRequest, SalesRow } from '../../types.js';
import { DEFAULT_SALESROW_ORDERLINES_LENGTH, statusToString } from '../../types.js';
import SalesOverviewFilter from '../salesoverview/SalesOverviewFilter.js';
import useNewSalesData from '../../hooks/useNewSalesData.js';
import { DateUtils } from '../../../sharedutils/index.js';

const getValueOrNA = <T, >(value: T) => {
  if (value === undefined || value === null) return 'N/A';
  return value;
};

const getCellValueOrNA = <T, >(info: CellContext<T, T[keyof T]>) => {
  const value = info.getValue();
  return getValueOrNA(value);
};

const columnHelper = createColumnHelper<SalesRow>();

const createOrderLines = (lines = DEFAULT_SALESROW_ORDERLINES_LENGTH) => Array.from({ length: lines })
  .map((_, i) => columnHelper.accessor((row) => row[`orderLine_${i}`], {
    header: `Ordrelinje ${i + 1}`,
    cell: (info) => {
      const value = info.getValue();
      return value ?? 'N/A';
    },
    id: `orderLine_${i}`,
  }));

const columns = [
  columnHelper.accessor((row) => row.status, {
    header: 'Status',
    cell: (info) => statusToString(info.getValue()),
    id: 'status',
  }),
  columnHelper.accessor((row) => row.date, {
    header: 'Dato',
    cell: (info) => info.getValue()?.toLocaleString(),
    filterFn: 'isWithinRange',
    id: 'date',
    sortingFn: 'datetime',
    sortDescFirst: true,
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.customerName, {
    header: 'Kundenavn',
    cell: (info) => info.getValue() ?? '',
    id: 'customerName',
  }),
  columnHelper.accessor((row) => row.customerPhone, {
    header: 'Tlf',
    cell: (info) => info.getValue() ?? '',
    id: 'customerPhone',
  }),
  columnHelper.accessor((row) => row.adviser, {
    header: 'Rådgiver navn',
    cell: (info) => info.getValue() ?? '',
    id: 'adviser',
  }),
  columnHelper.accessor((row) => row.orderName, {
    header: 'Ordrenr',
    cell: (info) => <MuiLink href={`${import.meta.env.VITE_FRONTEND_URL ?? window.location.origin}?order=${info.getValue()}`} target="_blank" rel="noreferrer noopener">{info.getValue()}</MuiLink>,
    id: 'orderName',
  }),
  columnHelper.accessor((row) => row.quoteLink, {
    header: 'Tilbudslink',
    cell: (info) => {
      const value = info.getValue();
      return value ? (<MuiLink href={value} target="_blank" rel="noreferrer noopener">Tilbud</MuiLink>) : 'N/A';
    },
    id: 'quoteLink',
  }),
  columnHelper.accessor((row) => row.originalPrice, {
    header: 'Veil pris ink. tillegg',
    cell: getCellValueOrNA,
    id: 'originalPrice',
  }),
  columnHelper.accessor((row) => row.price, {
    header: 'Pris',
    cell: getCellValueOrNA,
    id: 'price',
  }),
  columnHelper.accessor((row) => row.mamutOrderNr, {
    header: 'Mamutorder',
    cell: getCellValueOrNA,
    id: 'mamutOrderNr',
  }),
  columnHelper.accessor((row) => row.sevenOfficeOrderId, {
    header: '24SevenOffice Ordre ID',
    cell: getCellValueOrNA,
    id: 'sevenOfficeOrderId',
  }),
  columnHelper.accessor((row) => row.db, {
    header: 'DB',
    cell: getCellValueOrNA,
    id: 'db',
  }),
  columnHelper.accessor((row) => row.dg, {
    header: 'DG',
    cell: getCellValueOrNA,
    id: 'dg',
  }),
  columnHelper.accessor((row) => row.totalOrderLines, {
    header: 'Antall ordrelinjer',
    cell: (info) => info.getValue() ?? 'N/A',
    id: 'totalOrderLines',
  }),
  ...createOrderLines(),
  columnHelper.accessor((row) => row.pipeMetersAdviser, {
    header: 'Antall meter rør lagt inn av rådgiver',
    cell: getCellValueOrNA,
    id: 'pipeMetersAdviser',
  }),
  columnHelper.accessor((row) => row.pipeMetersInstaller, {
    header: 'Antall meter rør lagt inn av montør',
    cell: getCellValueOrNA,
    id: 'pipeMetersInstaller',
  }),
  columnHelper.accessor((row) => row.pipeDifference, {
    header: 'Rådgiver meter minus montør meter',
    cell: getCellValueOrNA,
    id: 'pipeDifference',
  }),
  columnHelper.accessor((row) => row.resursBank, {
    header: 'ResursBank',
    cell: (info) => {
      const value = info.getValue();
      if (value === true) return 'Ja';
      if (value === false) return 'Nei';
      return 'N/A';
    },
    id: 'resursBank',
  }),
  columnHelper.accessor((row) => row.saleRating, {
    header: 'Terning (montør)',
    cell: getCellValueOrNA,
    id: 'saleRating',
  }),
  columnHelper.accessor((row) => row.saleRatingAnsweredBy, {
    header: 'Hvilken montør',
    cell: getCellValueOrNA,
    id: 'saleRatingAnsweredBy',
  }),
  columnHelper.accessor((row) => row.customerRating, {
    header: 'Terning (kunde)',
    cell: getCellValueOrNA,
    id: 'customerRating',
  }),
  columnHelper.accessor((row) => row.notesForInstaller, {
    header: 'Notat til montør',
    cell: getCellValueOrNA,
    id: 'notes',
  }),
  columnHelper.accessor((row) => row.pumpChange, {
    header: 'Pumpe skal byttes ut?',
    cell: (info) => {
      const value = info.getValue();
      if (value === '1') return 'Ja';
      if (value === '0') return 'Nei';
      return 'N/A';
    },
    id: 'pumpChange',
  }),
] as ColumnDef<SalesRow>[];

const menuItems: { value: number, label: string }[] = [
  {
    value: 7,
    label: 'Siste uken',
  },
  {
    value: 14,
    label: 'Siste 2 uker',
  },
  {
    value: 30,
    label: 'Siste 30 dager',
  },
  {
    value: 60,
    label: 'Siste 60 dager',
  },
  {
    value: 90,
    label: 'Siste 3 måneder',
  },
  {
    value: 120,
    label: 'Siste 4 måneder',
  },
];

const SalesOverviewPage = () => {
  const [days, setDays] = useState<number>(30);
  const [hasSearched, setHasSearched] = useState<boolean>(true);

  const [request, setRequest] = useState<CompleteSalesOrderRequest>({
    to: new Date().toISOString(),
    from: DateUtils.subtractDateDays(new Date(), days).toISOString(),
  });

  const { data: salesData, isLoading } = useNewSalesData({ request });
  const initialSortingState = useMemo<SortingState>(() => [{ id: 'date', desc: true }], []);

  const onDaysChange = (event: SelectChangeEvent<number>) => {
    setHasSearched(false);
    setDays(event.target.value as number);
  };

  const onSearch = () => {
    setHasSearched(true);
    setRequest({
      to: new Date().toISOString(),
      from: DateUtils.subtractDateDays(new Date(), days).toISOString(),
    });
  };

  return (
    (
      <Box>
        <Typography variant="h2" gutterBottom>Salgsoversikt</Typography>
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Grid2>
            <FormControl>
              <InputLabel id="days-select-label">Intervall</InputLabel>
              <Select
                labelId="days-select-label"
                id="days-select"
                label="Intervall"
                value={days}
                onChange={onDaysChange}
              >
                {menuItems.map((x) => <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2>
            {hasSearched
              ? (
                <Tooltip title="Hent data på nytt">
                  <IconButton color="success" onClick={onSearch}><Autorenew /></IconButton>
                </Tooltip>
              )
              : <Button variant="contained" color="error" onClick={onSearch} disabled={isLoading}>Søk</Button>}
          </Grid2>
        </Grid2>
        <Divider />
        <GenericTable
          id="salesOverview"
          columns={columns}
          data={salesData}
          pagination
          initialSortingState={initialSortingState}
          Filter={SalesOverviewFilter}
        />
        <Backdrop open={isLoading}>
          <Stack
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size={60} />
          </Stack>
        </Backdrop>
      </Box>
    )
  );
};

export default SalesOverviewPage;
