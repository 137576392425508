import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

type UseSearchQueryProps<TSearch, TQueryData> = UseQueryOptions<TQueryData> | ((search: TSearch) => UseQueryOptions<TQueryData>);

const useSearchQuery = <TSearch, TQueryData>(query: UseSearchQueryProps<TSearch, TQueryData>, defaultData: TSearch) => {
  const [search, setSearch] = React.useState<TSearch>(defaultData);

  // TODO Save enabled state here?
  const memoedQuery = React.useMemo<UseQueryOptions<TQueryData>>(() => {
    if (typeof query === 'function') {
      return query(search);
    }
    return query;
  }, [query, search]);

  const queryData = useQuery(memoedQuery);

  return { queryData, refetch: setSearch, query: memoedQuery };
};

export default useSearchQuery;
