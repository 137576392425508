import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { ICpproUserContext } from '../contexts/CpproUserContext.js';
import CpproUserContext from '../contexts/CpproUserContext.js';
import type { User } from '../../CPpro/model/model.js';
import { UserPropertyType } from '../../CPpro/model/model.js';
import removeNullsAndUndefined from '../../CPpro/Utils/commonUtils.js';
import { getData, setData } from '../../CPpro/Utils/storage.js';
import { CPproQueries } from '../queries/index.js';
import { CommonUtils } from '../../sharedutils/index.js';
import { AccessTypes } from '../types/index.js';

export interface IUserData {
  readonly email: string,
  readonly groups: string[],
  readonly name?: string,
  readonly accessLevel?: string | number,
  readonly roles?: Record<AccessTypes.AccessRole, AccessTypes.AccessRoleLevel | undefined>,
  readonly isUserAdmin: boolean,
}

export interface IUserDataFunctions {
  checkRole(role: AccessTypes.AccessRole, level: AccessTypes.AccessRoleLevel): boolean
}

export class UserData implements IUserData, IUserDataFunctions {
  readonly email: string;
  readonly groups: string[];
  readonly name?: string | undefined;
  readonly accessLevel?: string | number | undefined;
  readonly roles?: Record<AccessTypes.AccessRole, AccessTypes.AccessRoleLevel | undefined> | undefined;
  readonly isUserAdmin: boolean;

  constructor(user: IUserData) {
    this.email = user.email;
    this.groups = user.groups;
    this.name = user.name;
    this.accessLevel = user.accessLevel;
    this.roles = user.roles;
    this.isUserAdmin = user.isUserAdmin;
  }

  checkRole(role: AccessTypes.AccessRole, level: AccessTypes.AccessRoleLevel): boolean {
    if (level === 'NONE') return true;
    if (!this.roles) return false;
    const roleLevel = this.roles[role];
    if (!roleLevel) return false;

    return AccessTypes.compareRoleLevels(level, roleLevel);
  }
}

const CpproUserProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [user, setUser] = useState<UserData | null>(null);
  const queryClient = useQueryClient();

  const { data: accessLevels, isLoading } = useQuery(CPproQueries.accessLevelsQuery);

  const getUserData = useCallback(async (userProp?: User) => {
    if (accessLevels) {
      const activeUser = userProp ?? JSON.parse((await getData('User')) ?? '{}') as User | undefined;

      if (activeUser?.properties) {
        const userData: IUserData = {
          email: activeUser.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString() ?? '',
          groups: activeUser.properties
            .filter((p) => p.type === UserPropertyType.Group)
            .map((p) => p.value.toString())
            .filter(removeNullsAndUndefined),
          name: activeUser.properties.find((p) => p.type === UserPropertyType.Name)?.value.toString(),
          accessLevel: activeUser.properties.find((p) => p.type === UserPropertyType.Accesslevel)?.value,
          roles: activeUser.properties
            .filter((p) => p.type === UserPropertyType.AccessRole)
            .map((p) => p.value.toString().split(':'))
            .filter((p) => p.length === 2)
            .reduce((acc, curr) => ({ ...acc, [curr[0]]: curr[1] }), {} as Record<AccessTypes.AccessRole, AccessTypes.AccessRoleLevel>),
          isUserAdmin: CommonUtils.checkUserAccessLevel(activeUser, 'USERADMIN', accessLevels),
        };

        setUser(new UserData(userData));
      } else {
        setUser(null);
      }
    }
  }, [accessLevels]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const refreshUser = useCallback(async () => {
    const userData = await CPproQueries.userQueryFn();

    getUserData(userData);
    if (userData) await setData('User', JSON.stringify(userData));
  }, [getUserData]);

  const refetchAccessLeves = useCallback(() => {
    queryClient.invalidateQueries(CPproQueries.accessLevelsQuery);
  }, [queryClient]);

  const providerValue = useMemo<ICpproUserContext>(() => ({
    user,
    refreshUser,
    refetchAccessLeves,
    isLoading,
  }), [isLoading, refetchAccessLeves, refreshUser, user]);

  return (
    <CpproUserContext.Provider value={providerValue}>
      {children}
    </CpproUserContext.Provider>
  );
};

export default CpproUserProvider;
