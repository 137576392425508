import React, { useMemo } from 'react';
import {
  Button, Typography,
} from '@mui/material';
import { Grid2 } from '@mui/material';
import { Box } from '@mui/material';
import type { GenericTableFilterProps } from '../table/GenericTable.js';
import AutocompleteFilter from '../table/filters/AutocompleteFilter.js';
import { CSVUtils } from '../../utils/index.js';
import type { CloudTask, ErpSyncOrderWithLinkAndTasks } from '../../../generated/activityhelper/api.js';
import CheckboxFilter from '../table/filters/CheckboxFilter.js';
import { removeFalsy } from '../../../CPpro/Utils/commonUtils.js';

const getLinkText = (link: string | undefined) => {
  if (!link) return '';
  const split = link.split('=');
  if (split.length >= 2) return split[1];
  return link;
};

const ERPOverviewFilter = (props: GenericTableFilterProps<ErpSyncOrderWithLinkAndTasks>) => {
  const { table, tableId } = props;

  const acErpInvoicedColumn = table.getColumn('acErpInvoiced');
  const fjErpInvoicedColumn = table.getColumn('fjErpInvoiced');
  const salesOrderColumn = table.getColumn('salesOrderLink');
  const installOrderColumn = table.getColumn('installOrderLink');
  const acErpIdColumn = table.getColumn('acErpId');
  const fjErpIdColumn = table.getColumn('fjErpId');
  const tasksColumn = table.getColumn('tasks');

  const salesOrderUniqueValues = salesOrderColumn?.getFacetedUniqueValues();
  const installOrderUniqueValues = installOrderColumn?.getFacetedUniqueValues();
  const tasksUniqueValues = tasksColumn?.getFacetedUniqueValues();

  const salesOrderOptions = useMemo(
    () => Array.from(salesOrderUniqueValues?.keys() ?? [])
      .filter(removeFalsy)
      .map(getLinkText).toSorted(),
    [salesOrderUniqueValues],
  );
  const installOrderOptions = useMemo(() => Array.from(installOrderUniqueValues?.keys() ?? [])
    .filter(removeFalsy)
    .map(getLinkText).toSorted(), [installOrderUniqueValues]);
  const tasksOptions = useMemo(() => [...new Set(Array.from(tasksUniqueValues?.keys() as unknown as CloudTask[][] ?? [])
    .filter((arr) => !!arr.length)
    .flatMap((arr) => arr.map((task) => task.httpRequest?.url?.replace(/\/\d+$/, '').replace(/^.*\.no/, '')))
    .filter(removeFalsy))].toSorted(), [tasksUniqueValues]);

  return (
    (
      <Box sx={{ padding: 1 }}>
        <Typography
          gutterBottom
          sx={{
            fontWeight: 'bold',
          }}
        >
          Filter:
        </Typography>
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
          }}
        >
          {salesOrderColumn ? (
            <Grid2>
              <AutocompleteFilter minWidth={350} column={salesOrderColumn} options={salesOrderOptions} label="Salgsordre" />
            </Grid2>
          ) : null}
          {installOrderColumn ? (
            <Grid2>
              <AutocompleteFilter minWidth={160} column={installOrderColumn} options={installOrderOptions} label="Monteringsordre" />
            </Grid2>
          ) : null}
          {acErpIdColumn ? (
            <Grid2>
              <AutocompleteFilter minWidth={160} column={acErpIdColumn} label="24SO AC(T)" />
            </Grid2>
          ) : null}
          {fjErpIdColumn ? (
            <Grid2>
              <AutocompleteFilter minWidth={160} column={fjErpIdColumn} label="24SO FJ Klima" />
            </Grid2>
          ) : null}
          {acErpInvoicedColumn ? (
            <Grid2>
              <CheckboxFilter column={acErpInvoicedColumn} label="AC Fakturert" onlyFilterOnTrue />
            </Grid2>
          ) : null}
          {fjErpInvoicedColumn ? (
            <Grid2>
              <CheckboxFilter column={fjErpInvoicedColumn} label="FJ Fakturert" onlyFilterOnTrue />
            </Grid2>
          ) : null}

          {tasksColumn ? (
            <Grid2>
              <AutocompleteFilter minWidth={350} column={tasksColumn} options={tasksOptions} label="Tasks" />
            </Grid2>
          ) : null}

          {tableId ? (
            <>
              <Grid2>
                <Button variant="contained" onClick={() => CSVUtils.downloadTableAsCSV(table, 'erpOversikt.csv', 'cpproId', true)}>
                  Last ned som CSV-fil
                </Button>
              </Grid2>
              {/* <Grid2>
              <Button variant="contained" onClick={() => CopyUtils.copyTableToClipboard(tableId, 'body')}>
                Kopier synlig tabellinnhold
              </Button>
            </Grid2> */}
              <Grid2>
                <Button variant="contained" onClick={() => CSVUtils.copyTableToClipboardAsCSV(table, 'cpproId', true)}>
                  Kopier filtrert tabell som CSV
                </Button>
              </Grid2>
            </>
          ) : null}
        </Grid2>
      </Box>
    )
  );
};

export default ERPOverviewFilter;
