import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import { Box } from '@mui/material';
import type { Column } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { removeFalsy } from '../../../CPpro/Utils/commonUtils.js';

type SelectFilterProps<T> = {
  column: Column<T>,
  label: string,
  menuItems?: readonly (string | { value: string, label: string })[],
  disableAll?: boolean,
  width?: number
};

const SelectFilter = <T, >(props: SelectFilterProps<T>) => {
  const {
    column, menuItems, label, disableAll, width,
  } = props;
  const filterValue = column.getFilterValue();

  const uniqueValues = column.getFacetedUniqueValues();

  const sortedUniqueValues = useMemo(() => Array.from(uniqueValues.keys()).filter(removeFalsy).sort(), [uniqueValues]);

  return (
    <Box>
      <FormControl>
        <InputLabel id="select_filter_label" shrink sx={{ backgroundColor: 'white' }}>
          {label}
        </InputLabel>
        <Select
          labelId="select_filter_label"
          id="select_filter"
          displayEmpty
          label={label}
          value={filterValue ?? ''}
          onChange={(e) => column.setFilterValue(e.target.value)}
          fullWidth
          sx={{ width: width ?? 100 }}
        >
          {!disableAll ? (
            <MenuItem key="all" value="">Alle</MenuItem>
          ) : null}
          {menuItems ? menuItems.map((i) => {
            const value = typeof i === 'string' ? i : i.value;
            const itemLabel = typeof i === 'string' ? i : i.label;
            return (
              <MenuItem key={value} value={value}>{itemLabel}</MenuItem>
            );
          }) : sortedUniqueValues.map((v) => (
            <MenuItem key={v} value={v}>{v}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectFilter;
