import React from 'react';
import { useForm } from '@tanstack/react-form';
import {
  FormControl, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { Box } from '@mui/material';
import { Grid2 } from '@mui/material';
import type { GetIncidentsCommand } from '../../../../generated/activityhelper/api.js';
import { GetIncidentsCommandStatusEnum } from '../../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

type Props = {
  defaultData: GetIncidentsCommand,
  onSearch: (data: GetIncidentsCommand) => void | Promise<void>,
  loading: boolean,
};

const MonitorIncidentSearch = (props: Props) => {
  const { defaultData, onSearch, loading } = props;

  const form = useForm<GetIncidentsCommand>({
    defaultValues: defaultData,
    onSubmit: async ({ value }) => {
      await onSearch(value);
    },
  });

  return (
    (
      <Box sx={{ marginBottom: 2 }}>
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
        >
          <Grid2
            container
            spacing={2}
            sx={{
              alignItems: 'center',
            }}
          >
            <Grid2>
              <form.Field name="startTime">
                {(field) => (
                  <TextField
                    label="Start"
                    type="datetime-local"
                    value={DateUtils.convertToMUI(field.state.value)}
                    onChange={(e) => field.handleChange(DateUtils.convertFromMUI(e.target.value))}
                  />
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Field name="endTime">
                {(field) => (
                  <TextField
                    label="End"
                    type="datetime-local"
                    value={DateUtils.convertToMUI(field.state.value)}
                    onChange={(e) => field.handleChange(DateUtils.convertFromMUI(e.target.value))}
                  />
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Field name="status">
                {(field) => (
                  <FormControl>
                    <InputLabel id="select_type_label" shrink sx={{ backgroundColor: 'white' }}>
                      Type
                    </InputLabel>
                    <Select
                      labelId="select_type_label"
                      displayEmpty
                      label="Type"
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(e.target.value as GetIncidentsCommandStatusEnum || undefined)}
                      fullWidth
                      sx={{ width: 100 }}
                    >
                      <MenuItem value="">ALL</MenuItem>
                      {Object.values(GetIncidentsCommandStatusEnum).map((v) => (
                        <MenuItem key={v} value={v}>{v}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <LoadingButton
                    buttonProps={{
                      variant: 'contained',
                      type: 'submit',
                      loading: isSubmitting || loading,
                      disabled: !canSubmit || isSubmitting || loading,
                    }}
                  >
                    Search
                  </LoadingButton>
                )}
              </form.Subscribe>
            </Grid2>
          </Grid2>
        </form>
      </Box>
    )
  );
};

export default MonitorIncidentSearch;
