import React, { useEffect, useState } from 'react';
import {
  Alert,
  Tab, TextField, Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import { Grid2 } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { PlayArrow } from '@mui/icons-material';
import { useCPproUserContext } from '../../../contexts/CpproUserContext.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import CreateServiceChecklistForm from '../../cppro/CreateServiceChecklistForm.js';
import type { GenerateServiceOrderChecklistRequest } from '../../../../generated/activityhelper/api.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import { ERPMutations } from '../../../mutations/index.js';
import CreateMamutOrderForm from '../../cppro/CreateMamutOrderForm.js';

const defaultServiceData: GenerateServiceOrderChecklistRequest = {
  advisorEmail: '',
  calendar: '',
  customer: {
    name: '',
    phone: '',
    address: '',
    zip: '',
    city: '',
    email: '',
  },
  orderType: 'Service',
  dateAndClock: undefined,
  serviceText: '',
  slotLength: '',
};

const MigrationHelpersPage = () => {
  const { user } = useCPproUserContext();

  const [tab, setTab] = useState<string>('0');
  const [calendarId, setCalendarId] = useState<string>(user?.email ?? '');

  useEffect(() => {
    if (user?.email) {
      setCalendarId(user.email);
    }
  }, [user?.email]);

  const smsMutation = useMutation(ERPMutations.sendCalendarSMSReminderMutation);
  const calendarMutation = useMutation(ERPMutations.createNotificationChannelMutation);

  return (
    (
      <Box>
        <MonitorPageTitle title="Hjelpefunksjoner for Mamut -> 24SO migrering" />
        <TabContext value={tab}>
          <TabList onChange={(_, value) => setTab(value)}>
            <Tab label="Service" value="0" />
            <Tab label="SMS" value="1" />
            <Tab label="Mamut" value="2" />
            <Tab label="Kalender" value="3" />
          </TabList>
          <TabPanel value="0">
            <Box>
              <Typography gutterBottom variant="h6">Opprett service ordre:</Typography>
              <CreateServiceChecklistForm defaultData={{ ...defaultServiceData, advisorEmail: user?.email ?? '' }} />
            </Box>
          </TabPanel>
          <TabPanel value="1">
            <Box>
              <Typography gutterBottom variant="h6">Kjør SMS påminnelse for kalender-aktiviteter:</Typography>
              <LoadingButton
                customIcon={<PlayArrow />}
                buttonProps={{
                  onClick: () => smsMutation.mutateAsync({}),
                  variant: 'contained',
                  color: 'success',
                  loading: smsMutation.isPending,
                  disabled: smsMutation.isPending,
                }}
              >
                Send kalender SMS-påminnelser
              </LoadingButton>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box>
              <Typography gutterBottom variant="h6">Opprett Mamut ordre for eksisterene ordre:</Typography>
              <CreateMamutOrderForm />
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box>
              <Typography gutterBottom variant="h6">Opprett kalender-overvåking:</Typography>
              <Typography>
                Husk å dele kalender med denne eposten:
                <br />
                <br />
                <code>{import.meta.env.VITE_ACTIVITYHELPER_CALENDAR_EMAIL ?? 'activityhelper-node@cppro-316116.iam.gserviceaccount.com'}</code>
                <br />
                <br />
                Kalender må vere din epost med mindre du har
                {' '}
                <code>USERADMIN</code>
                {' '}
                tilgang i CPPro (du har tilgang til User Editor).
              </Typography>
              <Grid2
                container
                spacing={2}
                sx={{
                  alignItems: 'center',
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <Grid2>
                  <TextField
                    label="Kalender-ID / epost"
                    title="Kalender-ID / epost"
                    value={calendarId}
                    onChange={(e) => setCalendarId(e.target.value)}
                  />
                </Grid2>
                <Grid2>
                  <LoadingButton
                    customIcon={<PlayArrow />}
                    buttonProps={{
                      onClick: () => calendarMutation.mutateAsync({ calendarId }),
                      variant: 'contained',
                      loading: calendarMutation.isPending,
                      disabled: calendarMutation.isPending,
                    }}
                  >
                    Overvåk
                  </LoadingButton>

                </Grid2>

              </Grid2>
              {calendarMutation.isSuccess ? (
                <Alert severity="success">Kalender blir overvåkt</Alert>
              ) : null}
              {calendarMutation.isError ? (
                <Alert severity="error">
                  {JSON.stringify(calendarMutation.error.response?.data ?? { message: calendarMutation.error.message }, undefined, 2)}
                </Alert>
              ) : null}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    )
  );
};

export default MigrationHelpersPage;
