import {
  Autocomplete, TextField,
} from '@mui/material';
import { Box } from '@mui/material';
import type { Column } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { removeFalsy } from '../../../../CPpro/Utils/commonUtils.js';

type AutocompleteFilterProps<T> = {
  column: Column<T>,
  label: string,
  options?: string[],
  minWidth?: number,
};

const AutocompleteFilter = <T, >(props: AutocompleteFilterProps<T>) => {
  const {
    column, options, label, minWidth,
  } = props;
  const filterValue = column.getFilterValue();

  const uniqueValues = column.getFacetedUniqueValues();

  const sortedUniqueValues = useMemo(() => Array.from(uniqueValues.keys()).filter(removeFalsy).sort(), [uniqueValues]);

  return (
    <Box>
      <Autocomplete
        freeSolo
        value={filterValue ?? ''}
        onChange={(_, value) => column.setFilterValue(value)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label={label} />}
        options={options ?? sortedUniqueValues}
        fullWidth
        sx={{ minWidth: minWidth ?? 250 }}
      />
    </Box>
  );
};

export default AutocompleteFilter;
