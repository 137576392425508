import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from '@tanstack/react-form';
import {
  FormControl, InputLabel, MenuItem, Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { Grid2 } from '@mui/material';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { Delete } from '@mui/icons-material';
import { MonitorMutations } from '../../../mutations/index.js';
import { MonitorQueries } from '../../../queries/index.js';

import type { AlertPoliciesResponseInner, NotificationChannelsResponseInnerPoliciesInner } from '../../../../generated/activityhelper/api.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

type PolicyNotifFormType = Omit<NotificationChannelsResponseInnerPoliciesInner, 'notificationId' | 'lastTriggered'>;
type PolicyNotifFormProps = {
  policyNotif?: NotificationChannelsResponseInnerPoliciesInner,
  policies: AlertPoliciesResponseInner[],
  notificationId: number,
  policyIdsNotUsed: number[],
};

const PolicyNotifForm = (props: PolicyNotifFormProps) => {
  const {
    policyNotif, policies, notificationId, policyIdsNotUsed,
  } = props;
  const queryClient = useQueryClient();

  const addMutation = useMutation(MonitorMutations.createPolicyNotificationMutation);
  const updateMutation = useMutation(MonitorMutations.updatePolicyNotificationMutation);
  const deleteMutation = useMutation({
    ...MonitorMutations.deletePolicyNotificationMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(MonitorQueries.notificationChannelsQuery);
    },
  });

  const unusedPolicies = useMemo(() => {
    if (!policyNotif) return policies.filter((p) => policyIdsNotUsed.includes(p.id));
    return policies.filter((p) => policyIdsNotUsed.includes(p.id) || p.id === policyNotif.policyId);
  }, [policies, policyIdsNotUsed, policyNotif]);

  const form = useForm<PolicyNotifFormType>({
    defaultValues: policyNotif ? {
      policyId: policyNotif.policyId,
      rateLimit: policyNotif.rateLimit,
    } : {
      policyId: 0,
      rateLimit: 300,
    },
    onSubmit: async ({ value }) => {
      if (policyNotif) {
        await updateMutation.mutateAsync({
          policyId: value.policyId.toString(),
          channelId: notificationId.toString(),
          updatePolicyNotificationCommand: {
            rateLimit: value.rateLimit || undefined,
          },
        });
      } else {
        await addMutation.mutateAsync({
          policyId: value.policyId.toString(),
          createPolicyNotificationCommand: {
            rateLimit: value.rateLimit,
          },
          channelId: notificationId.toString(),
        });
      }
      queryClient.invalidateQueries(MonitorQueries.notificationChannelsQuery);
      // handleClose();
    },
  });

  return (
    (
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
            marginBottom: 2,
          }}
        >
          <Grid2>
            <form.Field
              name="policyId"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.number().min(1),
              }}
            >
              {({ state, handleBlur, handleChange }) => (
                <FormControl>
                  <InputLabel id="select_policy_label" shrink sx={{ backgroundColor: 'white' }}>
                    Policy
                  </InputLabel>
                  <Select
                    labelId="select_policy_label"
                    displayEmpty
                    label="Policy"
                    value={state.value}
                    onChange={(e) => handleChange(Number(e.target.value))}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ width: 200 }}
                    size="small"
                    disabled={!!policyNotif}
                  >
                    {unusedPolicies.map((v) => (
                      <MenuItem key={v.id} value={v.id}>{v.displayName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field
              name="rateLimit"
              validatorAdapter={zodValidator()}
              validators={{
                onChange: z.optional(z.number().min(0)),
              }}
            >
              {({ state, handleBlur, handleChange }) => (
                <Tooltip title="How often to send the notification">
                  <TextField
                    type="number"
                    value={state.value}
                    onChange={(e) => handleChange(Number(e.target.value))}
                    onBlur={handleBlur}
                    label="Rate Limit (in seconds)"
                    sx={{ width: 150 }}
                    size="small"
                  />
                </Tooltip>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            {policyNotif ? (
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting, state.isDirty]}>
                {([canSubmit, isSubmitting, isDirty]) => (
                  <>
                    {isDirty ? (
                      <LoadingButton
                        buttonProps={{
                          color: 'primary',
                          type: 'submit',
                          disabled: !canSubmit || isSubmitting,
                          loading: isSubmitting,
                          sx: { mr: 1 },
                        }}
                        save
                      >
                        Update
                      </LoadingButton>
                    ) : null}
                    <LoadingButton
                      buttonProps={{
                        color: 'error',
                        loading: deleteMutation.isPending,
                        disabled: isSubmitting || deleteMutation.isPending,
                        onClick: () => deleteMutation.mutateAsync({ channelId: notificationId.toString(), policyId: policyNotif.policyId.toString() }),
                      }}
                      customIcon={<Delete />}
                    >
                      Delete
                    </LoadingButton>
                  </>

                )}
              </form.Subscribe>
            ) : (
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <LoadingButton
                    buttonProps={{
                      type: 'submit', color: 'success', loading: isSubmitting, disabled: !canSubmit || isSubmitting,
                    }}
                    save
                  >
                    Add
                  </LoadingButton>
                )}
              </form.Subscribe>
            )}
          </Grid2>
        </Grid2>
      </form>
    )
  );
};

export default PolicyNotifForm;
