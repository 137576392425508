import type {
  Order, Answer, Item, Product, Question, Checklist, ProductPropertyCustomValue,
} from '../model/model.js';
import {
  AnswerPropertyType, QuestionPropertyType,
  OrderPropertyType, ProductPropertyType,
} from '../model/model.js';
// import logger from './logger.js';

export const removeNulls = <T>(value: T | undefined | null): value is T => value !== null && value !== undefined;

export function getQuestionAnswers(order: Order | Item, qname: string | number): Answer[] | undefined {
  const answers: Answer[] = [];
  if (Object.prototype.hasOwnProperty.call(order, 'item')) {
    (order as Order).item.forEach((item: Item) => {
      if (item.product) {
        item.product.forEach((product: Product) => {
          if (product.checklist) {
            product.checklist.forEach((checklist) => {
              if (checklist.question) {
                checklist.question.forEach((question) => {
                  const questionname = question.properties.find((property) => property.type === QuestionPropertyType.Name);
                  if (question.id === qname || questionname?.value === qname) {
                    if (question.answer && question.answer.length > 0) {
                      question.answer.sort((a, b) => b.created - a.created);
                      answers.push(question.answer[0]);
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  if (Object.prototype.hasOwnProperty.call(order, 'product')) {
    if ((order as Item).product) {
      (order as Item).product.forEach((product: Product) => {
        if (product.checklist) {
          product.checklist.forEach((checklist) => {
            if (checklist.question) {
              checklist.question.forEach((question) => {
                const questionname = question.properties.find((property) => property.type === QuestionPropertyType.Name);
                if (question.id === qname || questionname?.value === qname) {
                  if (question.answer && question.answer.length > 0) {
                    question.answer.sort((a, b) => b.created - a.created);
                    answers.push(question.answer[0]);
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  // return answers if any assigned
  return answers.length > 0 ? answers : undefined;
}

export function getQuestionAnswer(order: Order | Item, qname: string | number): Answer | undefined {
  const answers: Answer[] = [];
  if (Object.prototype.hasOwnProperty.call(order, 'item')) {
    (order as Order).item.forEach((item: Item) => {
      if (item.product) {
        item.product.forEach((product: Product) => {
          if (product.checklist) {
            product.checklist.forEach((checklist) => {
              if (checklist.question) {
                checklist.question.forEach((question) => {
                  const questionname = question.properties.find((property) => property.type === QuestionPropertyType.Name);
                  if (question.id === qname || questionname?.value === qname) {
                    if (question.answer && question.answer.length > 0) {
                      question.answer.sort((a, b) => b.created - a.created);
                      answers.push(question.answer[0]);
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  if (Object.prototype.hasOwnProperty.call(order, 'product')) {
    if ((order as Item).product) {
      (order as Item).product.forEach((product: Product) => {
        if (product.checklist) {
          product.checklist.forEach((checklist) => {
            if (checklist.question) {
              checklist.question.forEach((question) => {
                const questionname = question.properties.find((property) => property.type === QuestionPropertyType.Name);
                if (questionname && questionname.value === qname) {
                  if (question.answer && question.answer.length > 0) {
                    question.answer.sort((a, b) => b.created - a.created);
                    answers.push(question.answer[0]);
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  // return answers if any assigned
  return answers.length > 0 ? answers[0] : undefined;
}

/**
 * Map all questions with a name to a Record with the answer as the value
 * Used for automatically mapping an order to a spreadsheetOrder's data column in the db
 */
export function getAllQuestionAnswers(order: Order): Record<string, string | number | undefined> {
  const data: Record<string, string | number | undefined> = {};
  if (order.item?.length) {
    for (const item of order.item) {
      if (item.product) {
        for (const product of item.product) {
          if (product.checklist) {
            for (const checklist of product.checklist) {
              if (checklist.question) {
                for (const question of checklist.question) {
                  const questionname = question.properties.find((property) => property.type === QuestionPropertyType.Name);
                  if (questionname) {
                    if (question.answer?.length) {
                      question.answer.sort((a, b) => b.created - a.created);
                      const value = question.answer[0].properties.find((property) => property.type === AnswerPropertyType.Answer)?.value;
                      data[questionname.value.toString()] = value;
                    } else {
                      data[questionname.value.toString()] = undefined;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return data;
}

/** Helper function to get the newest answer's property value with the type === AnswerPropertyType.Answer */
export function getQuestionAnswerProperty(order: Order | Item, qname: string | number): string | number | undefined {
  return getQuestionAnswer(order, qname)?.properties.find((property) => property.type === AnswerPropertyType.Answer)?.value;
}

export function getOrderNameEmail(order: Order): string | undefined {
  const orderName = order.properties.find((property) => property.type === OrderPropertyType.Name)?.value?.toString();
  const email = orderName?.replace(/\d+$/, '');
  return email;
}

export function getQuestion(order: Order, qname: string | number): Question | undefined {
  const q: Question[] = [];
  if (Object.prototype.hasOwnProperty.call(order, 'item')) {
    (order as Order).item.forEach((item: Item) => {
      if (item.product) {
        item.product.forEach((product: Product) => {
          if (product.checklist) {
            product.checklist.forEach((checklist) => {
              if (checklist.question) {
                checklist.question.forEach((question) => {
                  const questionname = question.properties.find((property) => property.type === QuestionPropertyType.Name);
                  if (question.id === qname || questionname?.value === qname) {
                    q.push(question);
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  // return answer if any assigned
  return q.length > 0 ? q[0] : undefined;
}

export function getQuestionChecklistAndItem(order: Order, qname: string): { checklist: Checklist | undefined, item: Item | undefined } {
  const c: Checklist[] = [];
  const i: Item[] = [];
  if (Object.prototype.hasOwnProperty.call(order, 'item')) {
    (order as Order).item.forEach((item: Item) => {
      if (item.product) {
        item.product.forEach((product: Product) => {
          if (product.checklist) {
            product.checklist.forEach((checklist) => {
              if (checklist.question) {
                checklist.question.forEach((question) => {
                  const questionname = question.properties.find((property) => property.type === QuestionPropertyType.Name);
                  if (questionname && questionname.value === qname) {
                    c.push(checklist);
                    i.push(item);
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  // return answer if any assigned
  return { checklist: c.length > 0 ? c[0] : undefined, item: i.length > 0 ? i[0] : undefined };
}

export const getAllQuoteLinks = (order: Order) => {
  const links: string[] = [];
  for (const item of order.item ?? []) {
    for (const product of item.product ?? []) {
      for (const checklist of product.checklist ?? []) {
        for (const question of checklist.question ?? []) {
          const questionName = question.properties.find((property) => property.type === QuestionPropertyType.Name)?.value.toString();
          const questionData = question.properties.find((property) => property.type === QuestionPropertyType.Data);
          if (question.id === 1487 && questionName === 'Quote' && questionData) {
            try {
              const json: { NOR: 'Tilbud' | 'Ordrebekreftelse', Link: string } = JSON.parse(questionData.value.toString());
              if (json.NOR !== 'Ordrebekreftelse') {
                links.push(json.Link);
              }
            } catch (e) {
              console.error(`getAllQuoteLinks Order ${ order.id } - ${(e as Error).message}`);
            }
          }
        }
      }
    }
  }
  return links;
};

export const calculateFinalProductPrice = (
  price: number,
  qty: number,
  vat: number,
  discount?: number,
  customProduct?: boolean,
) => {
  let sumCost = price;
  let costAfterDiscount = 0;
  if (customProduct) {
    sumCost *= qty;
  } else {
    sumCost = sumCost * vat * qty / 100;
  }
  if (discount && discount > 0) {
    if (discount <= 100) {
      costAfterDiscount = sumCost * (100 - discount) / 100;
    } else {
      costAfterDiscount = sumCost - discount;
      if (costAfterDiscount < 0) {
        costAfterDiscount = 0;
      }
    }
  } else {
    costAfterDiscount = sumCost;
  }
  return { costAfterDiscount, originalCost: sumCost };
};

// Taken from activityhelper-node!!! Update this if any changes happen??!
export function calculateProductCost(
  product: Product | undefined,
  productData: { id: number, qty: number, discount?: number },
  order: Order,
): { costAfterDiscount: number, originalCost: number } {
  if (!product) return { costAfterDiscount: 0, originalCost: 0 };
  const customProperty = product.properties.find((p) => p.type === ProductPropertyType.Custom);
  let sumCost = 0;
  let { discount } = productData;
  const vat = (product.properties.find((p) => p.type === ProductPropertyType.VAT)?.value as number || 25) + 100;
  if (customProperty) {
    try {
      // TODO parseFloat!
      // NB!!! Custom property -> value.price and value.discount are **question names** for where price and discount are (not the actual values!!)
      const value = JSON.parse(customProperty.value.toString()) as ProductPropertyCustomValue;
      // sumCost = (value.price ? Number(getQuestionAnswerProperty(order, value.price)) : 0);
      // discount = value.discount ? parseFloat(getQuestionAnswerProperty(order, value.discount)?.toString() ?? '0') : 0;
      sumCost = value.price ? parseInt(getQuestionAnswerProperty(order, value.price)?.toString() ?? '0', 10) : 0;
      discount = value.discount ? parseInt(getQuestionAnswerProperty(order, value.discount)?.toString() ?? '0', 10) : 0;
    } catch {
      console.log('Custom product property had an invalid json value');
    }
  } else {
    sumCost = parseInt(product.properties.find((p) => p.type === ProductPropertyType.PriceExVAT)?.value.toString() ?? '0', 10);
  }
  return calculateFinalProductPrice(sumCost, productData.qty, vat, discount, !!customProperty);
}
