import type { ButtonProps, DialogProps } from '@mui/material';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import React from 'react';

type ConfirmDialogProps = {
  /**
   * @default 'Yes'
   */
  buttonText?: string,
  /**
   * @default 'Confirm'
   */
  titleText?: string,
  /**
   * @default '''
   */
  descriptionText?: string,
  open: boolean,
  onClose: () => void,
  onConfirm: () => void,
  /**
   * @default 'sm'
   */
  maxWidth?: DialogProps['maxWidth'],
  /**
   * @default 'primary'
   */
  confirmColor?: ButtonProps['color'],
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    open, onClose, onConfirm, buttonText, titleText, descriptionText, maxWidth, confirmColor,
  } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth ?? 'sm'}>
      <DialogTitle>{titleText ?? 'Confirm'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{descriptionText ?? ''}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onConfirm} variant="contained" color={confirmColor ?? 'primary'}>{buttonText ?? 'Yes'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
