import type { HTTPMethods } from '../../../CPpro/Utils/http-common.js';
import { DateUtils } from '../../../sharedutils/index.js';

// **************
// SPECIFIC TO FRONTEND
// **************
export type LogEntryErpError = {
  metadata?: {
    response?: string,
    error?: {
      message?: string,
      code?: string,
      stack?: string,
    },
  },
  message?: string,
};

export type LogEntry<T = unknown> = {
  message?: string | null,
  data?: T | null,
  severity?: string | null,
  timestamp?: string | Date | null,
};

export const EmailStatuses = ['ANY', 'UNSENT', 'SENT', 'RESENT', 'ERROR', 'API_ERROR'] as const;
export type EmailStatus = typeof EmailStatuses[number];

export const logDefaultData: Required<LogEntriesCommand> = {
  startTime: DateUtils.getDatePickerDate(DateUtils.subtractDateHours(new Date(), 1)),
  endTime: DateUtils.getDatePickerDate(),
};

export const logFixedDefaultData = { ...logDefaultData, ...DateUtils.fixDateCommand(logDefaultData) };

export const emailSMSDefaultData: Required<GetEmailsCommand | GetSMSesCommand> = {
  startTime: DateUtils.getDatePickerDate(DateUtils.subtractDateHours(new Date(), 1)),
  endTime: DateUtils.getDatePickerDate(),
  status: 'UNSENT',
  from: '',
  orderId: '',
  retriesMoreThan: 0,
  to: '',
  type: '',
};

export const fixEmailSMSCommand = (command: Required<GetEmailsCommand | GetSMSesCommand>): GetEmailsCommand | GetSMSesCommand => {
  const {
    endTime, startTime, from, to, status, type, retriesMoreThan, orderId,
  } = command;
  return {
    endTime: endTime === '' ? undefined : endTime,
    startTime: startTime === '' ? undefined : startTime,
    status: status === 'ANY' ? undefined : status,
    from: from === '' ? undefined : from,
    to: to === '' ? undefined : to,
    orderId: orderId === '' || Number(orderId) <= 0 ? undefined : Number(orderId),
    retriesMoreThan: retriesMoreThan <= 0 ? undefined : retriesMoreThan,
    type: type === '' ? undefined : type,
  };
};

export const emailSMSFixedDefaultData = { ...fixEmailSMSCommand(emailSMSDefaultData), ...DateUtils.fixDateCommand(emailSMSDefaultData) };

// **************
// COMMON TYPES (ACTIVITYHELPER AND FRONTEND)
// **************
export type Email = {
  id: number
  orderId: number | null
  to: string
  cc: string | null
  bcc: string | null
  from: string
  subject: string
  html: Buffer
  hash: string
  attachments: object | null,
  replyTo: string | null
  inReplyTo: string | null
  references: string | null
  status: string
  type: string | null
  created: Date | string,
  deleteDate: Date | string | null
  sent: Date | string | null
  retries: number
  retry: boolean
  error: string | null
  messageId: string | null
  threadId: string | null
};

export type Sms = {
  id: number
  orderId: number | null
  to: string
  message: Buffer
  hash: string
  from: string
  reply: boolean | null
  status: string,
  type: string | null
  created: Date | string,
  deleteDate: Date | string | null
  sent: Date | string | null
  retries: number
  retry: boolean
  error: string | null
  sveveId: string | null
};

export type LeadAPIRequests = { code: number, status: string, count: number, statusMessage: string }[];
export type LeadAPIVendors = Record<string, number>;

export type DateCommand = {
  startTime?: string,
  endTime?: string,
};

export type PaginationCommand = {
  size?: number,
  page?: number,
};

export type LogEntriesCommand = DateCommand;

export type GetEmailsCommand = DateCommand & {
  status?: EmailStatus,
  to?: string,
  from?: string,
  orderId?: number | string,
  type?: string,
  retriesMoreThan?: number
};

export type GetSMSesCommand = DateCommand & GetEmailsCommand;
type Duration = { seconds?: number | null, nanos?: number | null };
type Attempt = {
  scheduleTime?: string | Date | null,
  dispatchTime?: string | Date | null,
  responseTime?: string | Date | null,
  responseStatus?: {
    code?: number | null,
    message?: string | null,
    details?: unknown[] | null,
  } | null,
};

export type QueueWithTasks = {
  name: string,
  path: string,
  state?: 'STATE_UNSPECIFIED' | 'RUNNING' | 'PAUSED' | 'DISABLED' | number | null,
  retryConfig?: {
    maxAttempts?: number | null,
    maxRetryDuration?: Duration | null,
    minBackoff?: Duration | null,
    maxBackoff?: Duration | null,
    maxDoublings?: number | null,
  } | null,
  tasks: Array<{
    name: string,
    path: string,
    createTime?: string | Date | null,
    scheduleTime?: string | Date | null,
    dispatchCount?: number | null,
    responseCount?: number | null,
    firstAttempt?: Attempt | null,
    lastAttempt?: Attempt | null,
    dispatchDeadline?: Duration | null,
    httpRequest?: {
      url?: string | null,
      body?: string | null,
      httpMethod?: HTTPMethods | null,
      headers?: Record<string, string> | null,
    } | null,
  }>
};
