import type { UseMutationOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type {
  DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest,
  DefaultApiApiErpOrderCpproId24soACInvoicePostRequest,
  DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest,
  DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest,
  DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest,
  DefaultApiApiErpOrderCpproIdMrpPickPostRequest,
  PostACInvoiceOrderResponse,
  PostChecklistInstalledResponse,
  PostInactivateFJAndInvoiceACResponse,
  PostMRPInvoiceResponse,
  PostMRPPickResponse,
  PostMRPPickResponseOneOf,
  PostFindMRPOrderResponse,
  DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest,
  DefaultApiApiErpOrderCpproIdMrpShipPostRequest,
  DefaultApiApiErpOrder24soCreateOrderTaskPostRequest,
  DefaultApiApiErpSmsSendCalendarRemindersPostRequest,
  CreateNotificationChannelRequest,
} from '../../generated/activityhelper/api.js';
import { monitorApi } from '../apis/apis.js';

interface ERPMutations {
  checklistInstalledMutation: UseMutationOptions<PostChecklistInstalledResponse, AxiosError,
  DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest>;
  acInvoiceOrderMutation: UseMutationOptions<PostACInvoiceOrderResponse, AxiosError,
  DefaultApiApiErpOrderCpproId24soACInvoicePostRequest>;
  mrpFindMRPOrderMutation: UseMutationOptions<PostFindMRPOrderResponse, AxiosError, DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest>;
  mrpPickOrderMutation: UseMutationOptions<PostMRPPickResponseOneOf[] | PostMRPPickResponse, AxiosError,
  DefaultApiApiErpOrderCpproIdMrpPickPostRequest>;
  mrpShipOrderMutation: UseMutationOptions<unknown, AxiosError, DefaultApiApiErpOrderCpproIdMrpShipPostRequest>;
  mrpInvoiceOrderMutation: UseMutationOptions<PostMRPInvoiceResponse, AxiosError, DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest>;
  inactivateFJAndInvoiceACOrderMutation: UseMutationOptions<PostInactivateFJAndInvoiceACResponse, AxiosError,
  DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest>;
  mrpManuallyInvoicedMutation: UseMutationOptions<unknown, AxiosError, DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest>;
  acCreateOrderTaskMutation: UseMutationOptions<unknown, AxiosError, DefaultApiApiErpOrder24soCreateOrderTaskPostRequest>;
  sendCalendarSMSReminderMutation: UseMutationOptions<string, AxiosError<string>, DefaultApiApiErpSmsSendCalendarRemindersPostRequest>;
}

export const checklistInstalledMutation: ERPMutations['checklistInstalledMutation'] = {
  mutationFn: async (vars) => {
    const response = await monitorApi.apiErpInstallOrderInstallCPproIdChecklistInstalledPost(vars);
    return response.data;
  },
};

export const acInvoiceOrderMutation: ERPMutations['acInvoiceOrderMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpOrderCpproId24soACInvoicePost(vars);
    return data;
  },
};

export const mrpFindMRPOrderMutation: ERPMutations['mrpFindMRPOrderMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpOrderCpproIdMrpFindMRPOrderPost(vars);
    return data;
  },
};

export const mrpPickOrderMutation: ERPMutations['mrpPickOrderMutation'] = {
  mutationFn: async (vars) => {
    const response = await monitorApi.apiErpOrderCpproIdMrpPickPost(vars);
    return response.data;
  },
};

export const mrpShipOrderMutation: ERPMutations['mrpShipOrderMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpOrderCpproIdMrpShipPost(vars);
    return data;
  },
};

export const mrpInvoiceOrderMutation: ERPMutations['mrpInvoiceOrderMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpOrderCpproIdMrpInvoicePost(vars);
    return data;
  },
};

export const inactivateFJAndInvoiceACOrderMutation: ERPMutations['inactivateFJAndInvoiceACOrderMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpOrderCpproId24soInactivateFJAndInvoiceACPost(vars);
    return data;
  },
};

export const mrpManuallyInvoicedMutation: ERPMutations['mrpManuallyInvoicedMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpOrderCpproIdMrpManuallyInvoicedPost(vars);
    return data;
  },
};

export const acCreateOrderTaskMutation: ERPMutations['acCreateOrderTaskMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpOrder24soCreateOrderTaskPost(vars);
    return data;
  },
};

export const sendCalendarSMSReminderMutation: ERPMutations['sendCalendarSMSReminderMutation'] = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiErpSmsSendCalendarRemindersPost(vars);
    return data as unknown as string;
  },
};

export const createNotificationChannelMutation: UseMutationOptions<unknown, AxiosError, CreateNotificationChannelRequest> = {
  mutationFn: async (vars) => {
    const { data } = await monitorApi.apiCalendarNotificationChannelPost({ createNotificationChannelRequest: vars });
    return data;
  },
};
