import { useQuery } from '@tanstack/react-query';
import React from 'react';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Grid2, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Cancel, CheckCircle, Help } from '@mui/icons-material';
import { ERPQueries } from '../../../queries/index.js';
import type { GenericTableFilterProps } from '../../table/GenericTable.js';
import GenericTable from '../../table/GenericTable.js';
import type { MrpPickErrorListInner } from '../../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import AutocompleteFilter from '../../table/filters/AutocompleteFilter.js';
import SelectFilter from '../../table/SelectFilter.js';

const columnHelper = createColumnHelper<MrpPickErrorListInner>();

const columns = [
  columnHelper.accessor((row) => row.date, {
    header: 'Dato avvik',
    id: 'date',
    sortDescFirst: true,
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  columnHelper.accessor((row) => row.status, {
    header: 'Status',
    id: 'status',
    cell: (info) => {
      switch (info.getValue()) {
        case 'OK':
          return <CheckCircle color="success" />;
        case 'ERROR':
          return <Cancel color="error" />;
        case 'UNKNOWN':
          return <Help color="inherit" />;
        default:
          return '?';
      }
    },
  }),
  columnHelper.accessor((row) => row.description, {
    header: 'Beskrivelse',
    id: 'description',
  }),
  columnHelper.accessor((row) => row.type, {
    header: 'Type',
    id: 'type',
  }),
  columnHelper.accessor((row) => row.fromWarehouse, {
    header: 'Fra lager',
    id: 'fromWarehouse',
  }),
  columnHelper.accessor((row) => row.toWarehouse, {
    header: 'Til lager',
    id: 'toWarehouse',
  }),
  columnHelper.accessor((row) => row.identifier, {
    header: 'Identifier plukket',
    id: 'identifier',
  }),
  columnHelper.accessor((row) => row.serialNumber, {
    header: 'Serienummer',
    id: 'serialNumber',
  }),
  columnHelper.accessor((row) => row.itemNumber, {
    header: 'Varenummer',
    id: 'itemNumber',
  }),
  columnHelper.accessor((row) => row.lineItemId, {
    header: 'Linjenr',
    id: 'lineItemId',
  }),
  columnHelper.accessor((row) => row.date, {
    header: 'Periode',
    id: 'dateMonthYear',
    cell: (info) => dayjs(info.getValue()).format('YYYY-MM'),
  }),
  columnHelper.accessor((row) => row.mrpId, {
    header: 'Referanse Masterplan',
    id: 'mrpId',
    filterFn: 'equals',
  }),
];

const initialSortingState: SortingState = [{ id: 'date', desc: true }];

const statusMenuItems = ['OK', 'ERROR', 'UNKNOWN'] as const;

const Filter = (props: GenericTableFilterProps<MrpPickErrorListInner>) => {
  const { table } = props;

  const mrpColumn = table.getColumn('mrpId');
  const statusColumn = table.getColumn('status');

  return (
    (
      <Box sx={{ padding: 1 }}>
        <Typography
          gutterBottom
          sx={{
            fontWeight: 'bold',
          }}
        >
          Filter:
        </Typography>
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
          }}
        >
          {statusColumn ? (
            <Grid2>
              <SelectFilter menuItems={statusMenuItems} column={statusColumn} label="Status" />
            </Grid2>
          ) : null}
          {mrpColumn ? (
            <Grid2>
              <AutocompleteFilter column={mrpColumn} label="Masterplan Ordrenummer" />
            </Grid2>
          ) : null}
        </Grid2>
      </Box>
    )
  );
};

const ERPMRPPickErrors = () => {
  // TODO Date picker
  const { data } = useQuery(ERPQueries.getMRPPickErrorsQuery({}));

  return (
    <Box>
      <MonitorPageTitle title="Masterplan Plukk Avvik / Feil" />
      <GenericTable
        id="mrp-pick-errors"
        pagination
        columns={columns}
        data={data ?? []}
        initialSortingState={initialSortingState}
        Filter={Filter}
      />
    </Box>
  );
};

export default ERPMRPPickErrors;
