import { Checkbox, FormControlLabel } from '@mui/material';
import { Box } from '@mui/material';
import type { Column } from '@tanstack/react-table';
import React from 'react';

type CheckboxFilterProps<T> = {
  column: Column<T>,
  label: string,
  onlyFilterOnTrue?: boolean,
};

const CheckboxFilter = <T, >(props: CheckboxFilterProps<T>) => {
  const { column, label, onlyFilterOnTrue } = props;
  const filterValue = column.getFilterValue();

  return (
    <Box>
      <FormControlLabel
        control={(
          <Checkbox
            checked={Boolean(filterValue ?? false)}
            onChange={(e) => column.setFilterValue(onlyFilterOnTrue && !e.target.checked ? undefined : e.target.checked)}
          />
      )}
        label={label}
      />
    </Box>
  );
};

export default CheckboxFilter;
