import type { AccessLevel, User } from '../CPpro/model/model.js';
import { UserPropertyType } from '../CPpro/model/model.js';

export const checkUserAccessLevel = (user: User, accessLevelName: string, accessLevels: AccessLevel[]) => {
  const userAccessLevel = Number(user.properties.find((p) => p.type === UserPropertyType.Accesslevel)?.value);
  if (Number.isNaN(userAccessLevel)) return false;

  const accessLevelToCheck = accessLevels.find((al) => al.accesslevel === accessLevelName);
  if (!accessLevelToCheck) return false;

  // eslint-disable-next-line no-bitwise
  return !!(2 ** (accessLevelToCheck.level - 1) & userAccessLevel);
};

export const empty = () => {
  throw new Error('Not implemented');
};
