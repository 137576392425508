import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

type Props = {
  handleClose: () => void
};

const DialogCloseButton = (props: Props) => {
  const { handleClose } = props;
  return (
    (
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <Close />
      </IconButton>
    )
  );
};

export default DialogCloseButton;