import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import type { GenericTableFilterProps } from '../../table/GenericTable.js';
import type { AlertPolicyIncidentsResponseInner } from '../../../../generated/activityhelper/api.js';
import SelectFilter from '../../table/SelectFilter.js';
import { MonitorQueries } from '../../../queries/index.js';

type MonitorIncidentTableFilterProps = GenericTableFilterProps<AlertPolicyIncidentsResponseInner & { policyName: string | undefined }>;

const MonitorIncidentTableFilter = (props: MonitorIncidentTableFilterProps) => {
  const { table } = props;

  const policies = useQuery(MonitorQueries.policiesQuery);
  const policyNameColumn = table.getColumn('policyName');
  const policyNames = useMemo(() => {
    if (!policies.data) return [];
    return policies.data.map((p) => p.displayName);
  }, [policies.data]);

  return (
    <Box sx={{ padding: 2 }}>
      {policyNameColumn ? (
        <SelectFilter column={policyNameColumn} label="Policy" menuItems={policyNames} width={250} />
      ) : null}
    </Box>
  );
};

export default MonitorIncidentTableFilter;
