/* tslint:disable */
/* eslint-disable */
/**
 * CPpro API
 * CPpro API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration.js';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common.js';
import type { RequestArgs } from './base.js';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base.js';

/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'item_id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'checklist_id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'seq_no': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Answer
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface AnswerPropertiesInner
 */
export interface AnswerPropertiesInner {
    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'type': number;
    /**
     * 
     * @type {AnswerPropertiesInnerValue}
     * @memberof AnswerPropertiesInner
     */
    'value': AnswerPropertiesInnerValue;
    /**
     * 
     * @type {string}
     * @memberof AnswerPropertiesInner
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerPropertiesInner
     */
    'strvalue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'numvalue'?: number | null;
}
/**
 * 
 * @export
 * @interface AnswerPropertiesInnerValue
 */
export interface AnswerPropertiesInnerValue {
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AnswerPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type AnswerPropertyType = typeof AnswerPropertyType[keyof typeof AnswerPropertyType];


/**
 * 
 * @export
 * @interface Checklist
 */
export interface Checklist {
    /**
     * 
     * @type {number}
     * @memberof Checklist
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Checklist
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Checklist
     */
    '_orderid'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Checklist
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ChecklistQuestionInner>}
     * @memberof Checklist
     */
    'question': Array<ChecklistQuestionInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChecklistPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ChecklistPropertyType = typeof ChecklistPropertyType[keyof typeof ChecklistPropertyType];


/**
 * 
 * @export
 * @interface ChecklistQuestionInner
 */
export interface ChecklistQuestionInner {
    /**
     * 
     * @type {string}
     * @memberof ChecklistQuestionInner
     */
    '_checklist'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_checklistid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_itemid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_product'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_order_id'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof ChecklistQuestionInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof ChecklistQuestionInner
     */
    'answer'?: Array<QuestionAnswerInner>;
}
/**
 * 
 * @export
 * @interface CreateAnswerCommand
 */
export interface CreateAnswerCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateAnswerCommand
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAnswerCommand
     */
    'checklist_id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateAnswerCommand
     */
    'seq_no': number;
    /**
     * 
     * @type {Array<CreateAnswerCommandAllOfPropertiesInner>}
     * @memberof CreateAnswerCommand
     */
    'properties': Array<CreateAnswerCommandAllOfPropertiesInner>;
}
/**
 * 
 * @export
 * @interface CreateAnswerCommandAllOfPropertiesInner
 */
export interface CreateAnswerCommandAllOfPropertiesInner {
    /**
     * 
     * @type {number}
     * @memberof CreateAnswerCommandAllOfPropertiesInner
     */
    'type': number;
    /**
     * 
     * @type {AnswerPropertiesInnerValue}
     * @memberof CreateAnswerCommandAllOfPropertiesInner
     */
    'value': AnswerPropertiesInnerValue;
    /**
     * 
     * @type {string}
     * @memberof CreateAnswerCommandAllOfPropertiesInner
     */
    'strvalue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAnswerCommandAllOfPropertiesInner
     */
    'numvalue'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateItemCommand
 */
export interface CreateItemCommand {
    /**
     * 
     * @type {Array<CreateAnswerCommandAllOfPropertiesInner>}
     * @memberof CreateItemCommand
     */
    'properties': Array<CreateAnswerCommandAllOfPropertiesInner>;
    /**
     * 
     * @type {Array<CreateItemCommandAnswerInner>}
     * @memberof CreateItemCommand
     */
    'answer'?: Array<CreateItemCommandAnswerInner>;
}
/**
 * 
 * @export
 * @interface CreateItemCommandAnswerInner
 */
export interface CreateItemCommandAnswerInner {
    /**
     * 
     * @type {number}
     * @memberof CreateItemCommandAnswerInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemCommandAnswerInner
     */
    'checklist_id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemCommandAnswerInner
     */
    'seq_no': number;
    /**
     * 
     * @type {Array<CreateAnswerCommandAllOfPropertiesInner>}
     * @memberof CreateItemCommandAnswerInner
     */
    'properties': Array<CreateAnswerCommandAllOfPropertiesInner>;
}
/**
 * 
 * @export
 * @interface CreateOrderCommand
 */
export interface CreateOrderCommand {
    /**
     * 
     * @type {Array<CreateAnswerCommandAllOfPropertiesInner>}
     * @memberof CreateOrderCommand
     */
    'properties': Array<CreateAnswerCommandAllOfPropertiesInner>;
    /**
     * 
     * @type {Array<CreateOrderCommandItemInner>}
     * @memberof CreateOrderCommand
     */
    'item': Array<CreateOrderCommandItemInner>;
}
/**
 * 
 * @export
 * @interface CreateOrderCommandItemInner
 */
export interface CreateOrderCommandItemInner {
    /**
     * 
     * @type {Array<CreateAnswerCommandAllOfPropertiesInner>}
     * @memberof CreateOrderCommandItemInner
     */
    'properties': Array<CreateAnswerCommandAllOfPropertiesInner>;
    /**
     * 
     * @type {Array<CreateItemCommandAnswerInner>}
     * @memberof CreateOrderCommandItemInner
     */
    'answer'?: Array<CreateItemCommandAnswerInner>;
}
/**
 * 
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    'deleted'?: string;
}
/**
 * 
 * @export
 * @interface CreatePropertyCommand
 */
export interface CreatePropertyCommand {
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyCommand
     */
    'type': number;
    /**
     * 
     * @type {AnswerPropertiesInnerValue}
     * @memberof CreatePropertyCommand
     */
    'value': AnswerPropertiesInnerValue;
    /**
     * 
     * @type {string}
     * @memberof CreatePropertyCommand
     */
    'strvalue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreatePropertyCommand
     */
    'numvalue'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateapikeyPostRequest
 */
export interface CreateapikeyPostRequest {
    /**
     * 
     * @type {any}
     * @memberof CreateapikeyPostRequest
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof CreateapikeyPostRequest
     */
    'data'?: any;
    /**
     * 
     * @type {any}
     * @memberof CreateapikeyPostRequest
     */
    'accesslevel'?: any;
    /**
     * 
     * @type {any}
     * @memberof CreateapikeyPostRequest
     */
    'expiredate'?: any;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Customer
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type CustomerPropertyType = typeof CustomerPropertyType[keyof typeof CustomerPropertyType];


/**
 * 
 * @export
 * @interface DB
 */
export interface DB {
    /**
     * 
     * @type {Array<DBOrderInner>}
     * @memberof DB
     */
    'order': Array<DBOrderInner>;
    /**
     * 
     * @type {Array<ProductChecklistInner>}
     * @memberof DB
     */
    'checklist': Array<ProductChecklistInner>;
    /**
     * 
     * @type {Array<DBUserInner>}
     * @memberof DB
     */
    'user': Array<DBUserInner>;
    /**
     * 
     * @type {Array<ChecklistQuestionInner>}
     * @memberof DB
     */
    'question': Array<ChecklistQuestionInner>;
    /**
     * 
     * @type {Array<ItemProductInner>}
     * @memberof DB
     */
    'product': Array<ItemProductInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DB
     */
    'questiontype': Array<DBQuestiontypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof DB
     */
    'submenu': string;
}

export const DBQuestiontypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31
} as const;

export type DBQuestiontypeEnum = typeof DBQuestiontypeEnum[keyof typeof DBQuestiontypeEnum];

/**
 * 
 * @export
 * @interface DBDates
 */
export interface DBDates {
    /**
     * 
     * @type {string}
     * @memberof DBDates
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DBDates
     */
    'deleted'?: string;
}
/**
 * 
 * @export
 * @interface DBOrderInner
 */
export interface DBOrderInner {
    /**
     * 
     * @type {number}
     * @memberof DBOrderInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DBOrderInner
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof DBOrderInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<OrderItemInner>}
     * @memberof DBOrderInner
     */
    'item': Array<OrderItemInner>;
}
/**
 * 
 * @export
 * @interface DBUserInner
 */
export interface DBUserInner {
    /**
     * 
     * @type {number}
     * @memberof DBUserInner
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof DBUserInner
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface Dataset
 */
export interface Dataset {
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'orderid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'itemid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'productid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'checklistid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'checklist': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'questionid': string;
}
/**
 * 
 * @export
 * @interface DeliveredProduct
 */
export interface DeliveredProduct {
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof DeliveredProduct
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeliveredProductPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10
} as const;

export type DeliveredProductPropertyType = typeof DeliveredProductPropertyType[keyof typeof DeliveredProductPropertyType];


/**
 * 
 * @export
 * @interface DrizzleChecklist
 */
export interface DrizzleChecklist {
    /**
     * 
     * @type {number}
     * @memberof DrizzleChecklist
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleChecklist
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrizzleChecklist
     */
    'deleted'?: string;
    /**
     * 
     * @type {Array<DrizzleChecklistAllOfProperties>}
     * @memberof DrizzleChecklist
     */
    'properties'?: Array<DrizzleChecklistAllOfProperties>;
    /**
     * 
     * @type {Array<DrizzleChecklistAllOfQuestion>}
     * @memberof DrizzleChecklist
     */
    'question': Array<DrizzleChecklistAllOfQuestion>;
}
/**
 * 
 * @export
 * @interface DrizzleChecklistAllOfProperties
 */
export interface DrizzleChecklistAllOfProperties {
    /**
     * 
     * @type {number}
     * @memberof DrizzleChecklistAllOfProperties
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleChecklistAllOfProperties
     */
    'strvalue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleChecklistAllOfProperties
     */
    'numvalue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleChecklistAllOfProperties
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof DrizzleChecklistAllOfProperties
     */
    'PropertyType_id': number;
}
/**
 * 
 * @export
 * @interface DrizzleChecklistAllOfQuestion
 */
export interface DrizzleChecklistAllOfQuestion {
    /**
     * 
     * @type {number}
     * @memberof DrizzleChecklistAllOfQuestion
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleChecklistAllOfQuestion
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrizzleChecklistAllOfQuestion
     */
    'deleted'?: string;
    /**
     * 
     * @type {Array<DrizzleChecklistAllOfProperties>}
     * @memberof DrizzleChecklistAllOfQuestion
     */
    'properties': Array<DrizzleChecklistAllOfProperties>;
    /**
     * 
     * @type {number}
     * @memberof DrizzleChecklistAllOfQuestion
     */
    'parent_id': number;
}
/**
 * 
 * @export
 * @interface DrizzleProduct
 */
export interface DrizzleProduct {
    /**
     * 
     * @type {number}
     * @memberof DrizzleProduct
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProduct
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProduct
     */
    'deleted'?: string;
    /**
     * 
     * @type {Array<DrizzleProductAllOfProperties>}
     * @memberof DrizzleProduct
     */
    'properties': Array<DrizzleProductAllOfProperties>;
    /**
     * 
     * @type {Array<DrizzleProductAllOfChecklist>}
     * @memberof DrizzleProduct
     */
    'checklist': Array<DrizzleProductAllOfChecklist>;
}
/**
 * 
 * @export
 * @interface DrizzleProductAllOfChecklist
 */
export interface DrizzleProductAllOfChecklist {
    /**
     * 
     * @type {number}
     * @memberof DrizzleProductAllOfChecklist
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProductAllOfChecklist
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProductAllOfChecklist
     */
    'deleted'?: string;
    /**
     * 
     * @type {Array<DrizzleProductAllOfProperties>}
     * @memberof DrizzleProductAllOfChecklist
     */
    'properties'?: Array<DrizzleProductAllOfProperties>;
    /**
     * 
     * @type {Array<DrizzleProductAllOfChecklistAllOfQuestionInner>}
     * @memberof DrizzleProductAllOfChecklist
     */
    'question': Array<DrizzleProductAllOfChecklistAllOfQuestionInner>;
}
/**
 * 
 * @export
 * @interface DrizzleProductAllOfChecklistAllOfQuestionInner
 */
export interface DrizzleProductAllOfChecklistAllOfQuestionInner {
    /**
     * 
     * @type {number}
     * @memberof DrizzleProductAllOfChecklistAllOfQuestionInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProductAllOfChecklistAllOfQuestionInner
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProductAllOfChecklistAllOfQuestionInner
     */
    'deleted'?: string;
    /**
     * 
     * @type {Array<DrizzleProductAllOfProperties>}
     * @memberof DrizzleProductAllOfChecklistAllOfQuestionInner
     */
    'properties': Array<DrizzleProductAllOfProperties>;
    /**
     * 
     * @type {number}
     * @memberof DrizzleProductAllOfChecklistAllOfQuestionInner
     */
    'parent_id': number;
}
/**
 * 
 * @export
 * @interface DrizzleProductAllOfProperties
 */
export interface DrizzleProductAllOfProperties {
    /**
     * 
     * @type {number}
     * @memberof DrizzleProductAllOfProperties
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProductAllOfProperties
     */
    'strvalue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleProductAllOfProperties
     */
    'numvalue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleProductAllOfProperties
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof DrizzleProductAllOfProperties
     */
    'PropertyType_id': number;
}
/**
 * 
 * @export
 * @interface DrizzleProperty
 */
export interface DrizzleProperty {
    /**
     * 
     * @type {number}
     * @memberof DrizzleProperty
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleProperty
     */
    'strvalue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleProperty
     */
    'numvalue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleProperty
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof DrizzleProperty
     */
    'PropertyType_id': number;
}
/**
 * 
 * @export
 * @interface DrizzleQuestion
 */
export interface DrizzleQuestion {
    /**
     * 
     * @type {number}
     * @memberof DrizzleQuestion
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleQuestion
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DrizzleQuestion
     */
    'deleted'?: string;
    /**
     * 
     * @type {Array<DrizzleQuestionAllOfProperties>}
     * @memberof DrizzleQuestion
     */
    'properties': Array<DrizzleQuestionAllOfProperties>;
    /**
     * 
     * @type {number}
     * @memberof DrizzleQuestion
     */
    'parent_id': number;
}
/**
 * 
 * @export
 * @interface DrizzleQuestionAllOfProperties
 */
export interface DrizzleQuestionAllOfProperties {
    /**
     * 
     * @type {number}
     * @memberof DrizzleQuestionAllOfProperties
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof DrizzleQuestionAllOfProperties
     */
    'strvalue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleQuestionAllOfProperties
     */
    'numvalue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DrizzleQuestionAllOfProperties
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof DrizzleQuestionAllOfProperties
     */
    'PropertyType_id': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FilePropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type FilePropertyType = typeof FilePropertyType[keyof typeof FilePropertyType];


/**
 * 
 * @export
 * @interface GetOrderSearchRequest
 */
export interface GetOrderSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof GetOrderSearchRequest
     */
    'serialNumberIndoor'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrderSearchRequest
     */
    'serialNumberOutdoor'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrderSearchRequest
     */
    'serialNumber': string;
}
/**
 * 
 * @export
 * @interface GetOrderSearchResponse
 */
export interface GetOrderSearchResponse {
    /**
     * 
     * @type {Array<GetOrderSearchResponseOrdersInner>}
     * @memberof GetOrderSearchResponse
     */
    'orders': Array<GetOrderSearchResponseOrdersInner>;
}
/**
 * 
 * @export
 * @interface GetOrderSearchResponseOrdersInner
 */
export interface GetOrderSearchResponseOrdersInner {
    /**
     * 
     * @type {number}
     * @memberof GetOrderSearchResponseOrdersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetOrderSearchResponseOrdersInner
     */
    'name': string;
    /**
     * 
     * @type {Array<SearchOrderItemAnswersInner>}
     * @memberof GetOrderSearchResponseOrdersInner
     */
    'answers': Array<SearchOrderItemAnswersInner>;
}
/**
 * 
 * @export
 * @interface GetOrdersOrderNameRequest
 */
export interface GetOrdersOrderNameRequest extends Array<number> {
}
/**
 * 
 * @export
 * @interface GetOrdersOrderNameResponse
 */
export interface GetOrdersOrderNameResponse extends Array<GetOrdersOrderNameResponseInner> {
}
/**
 * 
 * @export
 * @interface GetOrdersOrderNameResponseInner
 */
export interface GetOrdersOrderNameResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetOrdersOrderNameResponseInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetOrdersOrderNameResponseInner
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetSaleFromInstallNameResponse
 */
export interface GetSaleFromInstallNameResponse {
    /**
     * 
     * @type {number}
     * @memberof GetSaleFromInstallNameResponse
     */
    'saleId': number;
}
/**
 * 
 * @export
 * @interface GetSalesFromInstallNameResponse
 */
export interface GetSalesFromInstallNameResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetSalesFromInstallNameResponse
     */
    'saleIds': Array<number>;
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Item
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ItemProductInner>}
     * @memberof Item
     */
    'product': Array<ItemProductInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof Item
     */
    'answer'?: Array<QuestionAnswerInner>;
}
/**
 * 
 * @export
 * @interface ItemProductInner
 */
export interface ItemProductInner {
    /**
     * 
     * @type {number}
     * @memberof ItemProductInner
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof ItemProductInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ProductChecklistInner>}
     * @memberof ItemProductInner
     */
    'checklist': Array<ProductChecklistInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ItemPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type ItemPropertyType = typeof ItemPropertyType[keyof typeof ItemPropertyType];


/**
 * 
 * @export
 * @interface Lead
 */
export interface Lead {
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Lead
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LeadPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20
} as const;

export type LeadPropertyType = typeof LeadPropertyType[keyof typeof LeadPropertyType];


/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Order
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<OrderItemInner>}
     * @memberof Order
     */
    'item': Array<OrderItemInner>;
}
/**
 * 
 * @export
 * @interface OrderItemInner
 */
export interface OrderItemInner {
    /**
     * 
     * @type {number}
     * @memberof OrderItemInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemInner
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof OrderItemInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ItemProductInner>}
     * @memberof OrderItemInner
     */
    'product': Array<ItemProductInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof OrderItemInner
     */
    'answer'?: Array<QuestionAnswerInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17
} as const;

export type OrderPropertyType = typeof OrderPropertyType[keyof typeof OrderPropertyType];


/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Product
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ProductChecklistInner>}
     * @memberof Product
     */
    'checklist': Array<ProductChecklistInner>;
}
/**
 * 
 * @export
 * @interface ProductChecklistInner
 */
export interface ProductChecklistInner {
    /**
     * 
     * @type {number}
     * @memberof ProductChecklistInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductChecklistInner
     */
    'parent_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductChecklistInner
     */
    '_orderid'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof ProductChecklistInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ChecklistQuestionInner>}
     * @memberof ProductChecklistInner
     */
    'question': Array<ChecklistQuestionInner>;
}
/**
 * 
 * @export
 * @interface ProductPropertyCustomValue
 */
export interface ProductPropertyCustomValue {
    /**
     * 
     * @type {string}
     * @memberof ProductPropertyCustomValue
     */
    'text'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPropertyCustomValue
     */
    'price'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPropertyCustomValue
     */
    'discount'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProductPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19
} as const;

export type ProductPropertyType = typeof ProductPropertyType[keyof typeof ProductPropertyType];


/**
 * 
 * @export
 * @interface PropertyType
 */
export interface PropertyType {
    /**
     * 
     * @type {number}
     * @memberof PropertyType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyType
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    '_checklist'?: string;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_checklistid'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_itemid'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_product'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_order_id'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Question
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof Question
     */
    'answer'?: Array<QuestionAnswerInner>;
}
/**
 * 
 * @export
 * @interface QuestionAnswerInner
 */
export interface QuestionAnswerInner {
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'item_id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'checklist_id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'seq_no': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof QuestionAnswerInner
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const QuestionPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16
} as const;

export type QuestionPropertyType = typeof QuestionPropertyType[keyof typeof QuestionPropertyType];


/**
 * 
 * @export
 * @enum {string}
 */

export const QuestionType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31
} as const;

export type QuestionType = typeof QuestionType[keyof typeof QuestionType];


/**
 * 
 * @export
 * @interface SearchOrderItem
 */
export interface SearchOrderItem {
    /**
     * 
     * @type {number}
     * @memberof SearchOrderItem
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderItem
     */
    'name': string;
    /**
     * 
     * @type {Array<SearchOrderItemAnswersInner>}
     * @memberof SearchOrderItem
     */
    'answers': Array<SearchOrderItemAnswersInner>;
}
/**
 * 
 * @export
 * @interface SearchOrderItemAnswersInner
 */
export interface SearchOrderItemAnswersInner {
    /**
     * 
     * @type {AnswerPropertiesInnerValue}
     * @memberof SearchOrderItemAnswersInner
     */
    'value'?: AnswerPropertiesInnerValue;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderItemAnswersInner
     */
    'answeredBy'?: string;
    /**
     * 
     * @type {SearchOrderItemAnswersInnerDate}
     * @memberof SearchOrderItemAnswersInner
     */
    'date'?: SearchOrderItemAnswersInnerDate;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderItemAnswersInner
     */
    'question'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderItemAnswersInner
     */
    'model'?: string;
}
/**
 * @type SearchOrderItemAnswersInnerDate
 * @export
 */
export type SearchOrderItemAnswersInnerDate = string;

/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Service
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ServicePropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ServicePropertyType = typeof ServicePropertyType[keyof typeof ServicePropertyType];


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof User
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;

export type UserPropertyType = typeof UserPropertyType[keyof typeof UserPropertyType];


/**
 * 
 * @export
 * @interface Webhook
 */
export interface Webhook {
    /**
     * 
     * @type {number}
     * @memberof Webhook
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Webhook
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WebhookPropertyType = {
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20
} as const;

export type WebhookPropertyType = typeof WebhookPropertyType[keyof typeof WebhookPropertyType];


/**
 * 
 * @export
 * @interface WebhookQueueItem
 */
export interface WebhookQueueItem {
    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'retrycounter': number;
    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'dataId': number;
    /**
     * 
     * @type {string}
     * @memberof WebhookQueueItem
     */
    'url': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdOrderNameGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderIdOrderNameGet', 'id', id)
            const localVarPath = `/api/order/{id}/orderName`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdSaleFromInstallNameNameGet: async (id: string, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderIdSaleFromInstallNameNameGet', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiOrderIdSaleFromInstallNameNameGet', 'name', name)
            const localVarPath = `/api/order/{id}/saleFromInstallName/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdSalesFromInstallNameNameGet: async (id: string, name: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiOrderIdSalesFromInstallNameNameGet', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiOrderIdSalesFromInstallNameNameGet', 'name', name)
            const localVarPath = `/api/order/{id}/salesFromInstallName/{name}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrderCommand} createOrderCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderPost: async (createOrderCommand: CreateOrderCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrderCommand' is not null or undefined
            assertParamExists('apiOrderPost', 'createOrderCommand', createOrderCommand)
            const localVarPath = `/api/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrderCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOrderSearchRequest} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderSearchGet: async (query?: GetOrderSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/order/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetOrdersOrderNameRequest} getOrdersOrderNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrderNamePost: async (getOrdersOrderNameRequest: GetOrdersOrderNameRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getOrdersOrderNameRequest' is not null or undefined
            assertParamExists('apiOrdersOrderNamePost', 'getOrdersOrderNameRequest', getOrdersOrderNameRequest)
            const localVarPath = `/api/orders/orderName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getOrdersOrderNameRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdChecklistQuestionsGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiProductIdChecklistQuestionsGet', 'id', id)
            const localVarPath = `/api/product/{id}/checklistQuestions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cP2OrderClientOrderidGet: async (client: string, orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('cP2OrderClientOrderidGet', 'client', client)
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cP2OrderClientOrderidGet', 'orderid', orderid)
            const localVarPath = `/CP2_order/{client}/{orderid}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPDFFilenamePost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/createPDF/{filename}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateapikeyPostRequest} [createapikeyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createapikeyPost: async (createapikeyPostRequest?: CreateapikeyPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/createapikey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createapikeyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportLatestMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hourReport/latest/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportOverviewAllGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hourReport/overview/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportOverviewEmailGet: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('hourReportOverviewEmailGet', 'email', email)
            const localVarPath = `/hourReport/overview/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportOverviewMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hourReport/overview/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportTodayMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hourReport/today/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jSON2OrderPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/JSON2Order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mamutOrderClientOrderidGet: async (client: string, orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('mamutOrderClientOrderidGet', 'client', client)
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('mamutOrderClientOrderidGet', 'orderid', orderid)
            const localVarPath = `/Mamut_order/{client}/{orderid}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oldquotesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oldquotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAnswersIdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orderAnswers/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAnswersIdQuestionidGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orderAnswers/{id}/{questionid}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderdataGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orderdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordershardIdDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ordershard/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentOrderIdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*/parentOrder/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*Properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*Properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesIdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*Properties/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*Properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*Properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*PropertyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*PropertyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesIdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*PropertyTypes/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*PropertyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*PropertyTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerValueGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questionAnswer/{value}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerValueIdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questionAnswer/{value}/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sFindSearchtermGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*s/find/{searchterm}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sIdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*s/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sParameterParamFindSearchtermGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*s/parameter/{param}/find/{searchterm}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/*s`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewCompleteSalesOrdersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesOverview/completeSalesOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewOrderDataGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesOverview/orderData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewSalesDataGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesOverview/salesData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewSalesDataProductLinesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesOverview/salesDataProductLines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewSalesOrdersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesOverview/salesOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesdataGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [customkey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testapikeyGet: async (authorization?: string, customkey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/testapikey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (customkey != null) {
                localVarHeaderParameter['customkey'] = String(customkey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useremailGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/useremail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderIdOrderNameGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderIdOrderNameGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiOrderIdOrderNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderIdSaleFromInstallNameNameGet(id: string, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSaleFromInstallNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderIdSaleFromInstallNameNameGet(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiOrderIdSaleFromInstallNameNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderIdSalesFromInstallNameNameGet(id: string, name: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSalesFromInstallNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderIdSalesFromInstallNameNameGet(id, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiOrderIdSalesFromInstallNameNameGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateOrderCommand} createOrderCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderPost(createOrderCommand: CreateOrderCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderPost(createOrderCommand, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetOrderSearchRequest} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderSearchGet(query?: GetOrderSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrderSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderSearchGet(query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiOrderSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetOrdersOrderNameRequest} getOrdersOrderNameRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrdersOrderNamePost(getOrdersOrderNameRequest: GetOrdersOrderNameRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrdersOrderNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrdersOrderNamePost(getOrdersOrderNameRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiOrdersOrderNamePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProductIdChecklistQuestionsGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DrizzleProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProductIdChecklistQuestionsGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiProductIdChecklistQuestionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} client 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cP2OrderClientOrderidGet(client: string, orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cP2OrderClientOrderidGet(client, orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cP2OrderClientOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPDFFilenamePost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPDFFilenamePost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createPDFFilenamePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateapikeyPostRequest} [createapikeyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createapikeyPost(createapikeyPostRequest?: CreateapikeyPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createapikeyPost(createapikeyPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createapikeyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.healthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hourReportLatestMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hourReportLatestMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.hourReportLatestMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hourReportOverviewAllGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hourReportOverviewAllGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.hourReportOverviewAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hourReportOverviewEmailGet(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hourReportOverviewEmailGet(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.hourReportOverviewEmailGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hourReportOverviewMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hourReportOverviewMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.hourReportOverviewMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hourReportTodayMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hourReportTodayMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.hourReportTodayMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jSON2OrderPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jSON2OrderPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.jSON2OrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} client 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mamutOrderClientOrderidGet(client: string, orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mamutOrderClientOrderidGet(client, orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.mamutOrderClientOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oldquotesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oldquotesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.oldquotesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderAnswersIdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderAnswersIdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderAnswersIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderAnswersIdQuestionidGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderAnswersIdQuestionidGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderAnswersIdQuestionidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderdataGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderdataGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ordershardIdDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ordershardIdDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.ordershardIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parentOrderIdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parentOrderIdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.parentOrderIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertiesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesIdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesIdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertiesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertiesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertiesPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertiesPut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertiesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyTypesDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyTypesDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertyTypesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyTypesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyTypesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertyTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyTypesIdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyTypesIdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertyTypesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyTypesPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyTypesPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertyTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async propertyTypesPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.propertyTypesPut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.propertyTypesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnswerValueGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionAnswerValueGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.questionAnswerValueGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnswerValueIdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionAnswerValueIdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.questionAnswerValueIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sFindSearchtermGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sFindSearchtermGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sFindSearchtermGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sIdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sIdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sParameterParamFindSearchtermGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sParameterParamFindSearchtermGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sParameterParamFindSearchtermGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sPut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.sPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesOverviewCompleteSalesOrdersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesOverviewCompleteSalesOrdersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.salesOverviewCompleteSalesOrdersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesOverviewOrderDataGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesOverviewOrderDataGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.salesOverviewOrderDataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesOverviewSalesDataGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesOverviewSalesDataGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.salesOverviewSalesDataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesOverviewSalesDataProductLinesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesOverviewSalesDataProductLinesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.salesOverviewSalesDataProductLinesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesOverviewSalesOrdersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesOverviewSalesOrdersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.salesOverviewSalesOrdersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesdataGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesdataGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.salesdataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [customkey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testapikeyGet(authorization?: string, customkey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testapikeyGet(authorization, customkey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.testapikeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useremailGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useremailGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.useremailGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {DefaultApiApiOrderIdOrderNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdOrderNameGet(requestParameters: DefaultApiApiOrderIdOrderNameGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiOrderIdOrderNameGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiOrderIdSaleFromInstallNameNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdSaleFromInstallNameNameGet(requestParameters: DefaultApiApiOrderIdSaleFromInstallNameNameGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetSaleFromInstallNameResponse> {
            return localVarFp.apiOrderIdSaleFromInstallNameNameGet(requestParameters.id, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiOrderIdSalesFromInstallNameNameGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderIdSalesFromInstallNameNameGet(requestParameters: DefaultApiApiOrderIdSalesFromInstallNameNameGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetSalesFromInstallNameResponse> {
            return localVarFp.apiOrderIdSalesFromInstallNameNameGet(requestParameters.id, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderPost(requestParameters: DefaultApiApiOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CreateOrderResponse> {
            return localVarFp.apiOrderPost(requestParameters.createOrderCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiOrderSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderSearchGet(requestParameters: DefaultApiApiOrderSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetOrderSearchResponse> {
            return localVarFp.apiOrderSearchGet(requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiOrdersOrderNamePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrdersOrderNamePost(requestParameters: DefaultApiApiOrdersOrderNamePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetOrdersOrderNameResponse> {
            return localVarFp.apiOrdersOrderNamePost(requestParameters.getOrdersOrderNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiProductIdChecklistQuestionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProductIdChecklistQuestionsGet(requestParameters: DefaultApiApiProductIdChecklistQuestionsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DrizzleProduct> {
            return localVarFp.apiProductIdChecklistQuestionsGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCP2OrderClientOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cP2OrderClientOrderidGet(requestParameters: DefaultApiCP2OrderClientOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cP2OrderClientOrderidGet(requestParameters.client, requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPDFFilenamePost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createPDFFilenamePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCreateapikeyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createapikeyPost(requestParameters: DefaultApiCreateapikeyPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createapikeyPost(requestParameters.createapikeyPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportLatestMeGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hourReportLatestMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportOverviewAllGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hourReportOverviewAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiHourReportOverviewEmailGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportOverviewEmailGet(requestParameters: DefaultApiHourReportOverviewEmailGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hourReportOverviewEmailGet(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportOverviewMeGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hourReportOverviewMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hourReportTodayMeGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hourReportTodayMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jSON2OrderPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.jSON2OrderPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMamutOrderClientOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mamutOrderClientOrderidGet(requestParameters: DefaultApiMamutOrderClientOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.mamutOrderClientOrderidGet(requestParameters.client, requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oldquotesGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.oldquotesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAnswersIdGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.orderAnswersIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderAnswersIdQuestionidGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.orderAnswersIdQuestionidGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderdataGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.orderdataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ordershardIdDelete(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.ordershardIdDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parentOrderIdGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.parentOrderIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesDelete(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertiesDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesIdGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertiesIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertiesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertiesPut(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertiesPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesDelete(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertyTypesDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertyTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesIdGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertyTypesIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertyTypesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        propertyTypesPut(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.propertyTypesPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerValueGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.questionAnswerValueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnswerValueIdGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.questionAnswerValueIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sDelete(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sFindSearchtermGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sFindSearchtermGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sIdGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sParameterParamFindSearchtermGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sParameterParamFindSearchtermGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sPut(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.sPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewCompleteSalesOrdersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.salesOverviewCompleteSalesOrdersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewOrderDataGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.salesOverviewOrderDataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewSalesDataGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.salesOverviewSalesDataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewSalesDataProductLinesGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.salesOverviewSalesDataProductLinesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesOverviewSalesOrdersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.salesOverviewSalesOrdersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesdataGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.salesdataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiTestapikeyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testapikeyGet(requestParameters: DefaultApiTestapikeyGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.testapikeyGet(requestParameters.authorization, requestParameters.customkey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useremailGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.useremailGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiOrderIdOrderNameGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiOrderIdOrderNameGetRequest
 */
export interface DefaultApiApiOrderIdOrderNameGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiOrderIdOrderNameGet
     */
    readonly id: string
}

/**
 * Request parameters for apiOrderIdSaleFromInstallNameNameGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiOrderIdSaleFromInstallNameNameGetRequest
 */
export interface DefaultApiApiOrderIdSaleFromInstallNameNameGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiOrderIdSaleFromInstallNameNameGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiOrderIdSaleFromInstallNameNameGet
     */
    readonly name: string
}

/**
 * Request parameters for apiOrderIdSalesFromInstallNameNameGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiOrderIdSalesFromInstallNameNameGetRequest
 */
export interface DefaultApiApiOrderIdSalesFromInstallNameNameGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiOrderIdSalesFromInstallNameNameGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiOrderIdSalesFromInstallNameNameGet
     */
    readonly name: string
}

/**
 * Request parameters for apiOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiOrderPostRequest
 */
export interface DefaultApiApiOrderPostRequest {
    /**
     * 
     * @type {CreateOrderCommand}
     * @memberof DefaultApiApiOrderPost
     */
    readonly createOrderCommand: CreateOrderCommand
}

/**
 * Request parameters for apiOrderSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiOrderSearchGetRequest
 */
export interface DefaultApiApiOrderSearchGetRequest {
    /**
     * 
     * @type {GetOrderSearchRequest}
     * @memberof DefaultApiApiOrderSearchGet
     */
    readonly query?: GetOrderSearchRequest
}

/**
 * Request parameters for apiOrdersOrderNamePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiOrdersOrderNamePostRequest
 */
export interface DefaultApiApiOrdersOrderNamePostRequest {
    /**
     * 
     * @type {GetOrdersOrderNameRequest}
     * @memberof DefaultApiApiOrdersOrderNamePost
     */
    readonly getOrdersOrderNameRequest: GetOrdersOrderNameRequest
}

/**
 * Request parameters for apiProductIdChecklistQuestionsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiProductIdChecklistQuestionsGetRequest
 */
export interface DefaultApiApiProductIdChecklistQuestionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiProductIdChecklistQuestionsGet
     */
    readonly id: string
}

/**
 * Request parameters for cP2OrderClientOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCP2OrderClientOrderidGetRequest
 */
export interface DefaultApiCP2OrderClientOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCP2OrderClientOrderidGet
     */
    readonly client: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiCP2OrderClientOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for createapikeyPost operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateapikeyPostRequest
 */
export interface DefaultApiCreateapikeyPostRequest {
    /**
     * 
     * @type {CreateapikeyPostRequest}
     * @memberof DefaultApiCreateapikeyPost
     */
    readonly createapikeyPostRequest?: CreateapikeyPostRequest
}

/**
 * Request parameters for hourReportOverviewEmailGet operation in DefaultApi.
 * @export
 * @interface DefaultApiHourReportOverviewEmailGetRequest
 */
export interface DefaultApiHourReportOverviewEmailGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiHourReportOverviewEmailGet
     */
    readonly email: string
}

/**
 * Request parameters for mamutOrderClientOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMamutOrderClientOrderidGetRequest
 */
export interface DefaultApiMamutOrderClientOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMamutOrderClientOrderidGet
     */
    readonly client: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMamutOrderClientOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for testapikeyGet operation in DefaultApi.
 * @export
 * @interface DefaultApiTestapikeyGetRequest
 */
export interface DefaultApiTestapikeyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiTestapikeyGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiTestapikeyGet
     */
    readonly customkey?: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {DefaultApiApiOrderIdOrderNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrderIdOrderNameGet(requestParameters: DefaultApiApiOrderIdOrderNameGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrderIdOrderNameGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiOrderIdSaleFromInstallNameNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrderIdSaleFromInstallNameNameGet(requestParameters: DefaultApiApiOrderIdSaleFromInstallNameNameGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrderIdSaleFromInstallNameNameGet(requestParameters.id, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiOrderIdSalesFromInstallNameNameGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrderIdSalesFromInstallNameNameGet(requestParameters: DefaultApiApiOrderIdSalesFromInstallNameNameGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrderIdSalesFromInstallNameNameGet(requestParameters.id, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrderPost(requestParameters: DefaultApiApiOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrderPost(requestParameters.createOrderCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiOrderSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrderSearchGet(requestParameters: DefaultApiApiOrderSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrderSearchGet(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiOrdersOrderNamePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiOrdersOrderNamePost(requestParameters: DefaultApiApiOrdersOrderNamePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiOrdersOrderNamePost(requestParameters.getOrdersOrderNameRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiProductIdChecklistQuestionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiProductIdChecklistQuestionsGet(requestParameters: DefaultApiApiProductIdChecklistQuestionsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiProductIdChecklistQuestionsGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCP2OrderClientOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cP2OrderClientOrderidGet(requestParameters: DefaultApiCP2OrderClientOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cP2OrderClientOrderidGet(requestParameters.client, requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createPDFFilenamePost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createPDFFilenamePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCreateapikeyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createapikeyPost(requestParameters: DefaultApiCreateapikeyPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createapikeyPost(requestParameters.createapikeyPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hourReportLatestMeGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hourReportLatestMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hourReportOverviewAllGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hourReportOverviewAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiHourReportOverviewEmailGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hourReportOverviewEmailGet(requestParameters: DefaultApiHourReportOverviewEmailGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hourReportOverviewEmailGet(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hourReportOverviewMeGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hourReportOverviewMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hourReportTodayMeGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hourReportTodayMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public jSON2OrderPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).jSON2OrderPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMamutOrderClientOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mamutOrderClientOrderidGet(requestParameters: DefaultApiMamutOrderClientOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mamutOrderClientOrderidGet(requestParameters.client, requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public oldquotesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).oldquotesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderAnswersIdGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderAnswersIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderAnswersIdQuestionidGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderAnswersIdQuestionidGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderdataGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderdataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ordershardIdDelete(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ordershardIdDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public parentOrderIdGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).parentOrderIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesDelete(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesIdGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertiesPut(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertiesPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertyTypesDelete(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertyTypesDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertyTypesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertyTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertyTypesIdGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertyTypesIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertyTypesPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertyTypesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public propertyTypesPut(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).propertyTypesPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public questionAnswerValueGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).questionAnswerValueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public questionAnswerValueIdGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).questionAnswerValueIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sDelete(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sFindSearchtermGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sFindSearchtermGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sIdGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sParameterParamFindSearchtermGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sParameterParamFindSearchtermGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sPut(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public salesOverviewCompleteSalesOrdersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).salesOverviewCompleteSalesOrdersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public salesOverviewOrderDataGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).salesOverviewOrderDataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public salesOverviewSalesDataGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).salesOverviewSalesDataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public salesOverviewSalesDataProductLinesGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).salesOverviewSalesDataProductLinesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public salesOverviewSalesOrdersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).salesOverviewSalesOrdersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public salesdataGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).salesdataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiTestapikeyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testapikeyGet(requestParameters: DefaultApiTestapikeyGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testapikeyGet(requestParameters.authorization, requestParameters.customkey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public useremailGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).useremailGet(options).then((request) => request(this.axios, this.basePath));
    }
}



