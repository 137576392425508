import type { UseQueryOptions } from '@tanstack/react-query';
import type {
  DateCommand,
  DefaultApiApiErpErpSyncOrdersGetRequest,
  EndpointTask,
  ErpSyncOrderWithLinkAndTasks,
  GetErpSyncOrderCommand, GetErpSyncOrderResponse, GetMRPSalesOrderResponse,
  MrpPickErrorList,
} from '../../generated/activityhelper/api.js';
import { monitorApi } from '../apis/apis.js';

interface ERPQueries {
  getErpSyncOrderQuery(command: GetErpSyncOrderCommand | undefined): UseQueryOptions<GetErpSyncOrderResponse | undefined>;
  getErpSyncOrdersQuery(command: DefaultApiApiErpErpSyncOrdersGetRequest | undefined): UseQueryOptions<{
    erpSyncOrders: ErpSyncOrderWithLinkAndTasks[],
    endpointTasks: EndpointTask[] }>;
  getMRPSalesOrderQuery(cpproId: string | undefined): UseQueryOptions<GetMRPSalesOrderResponse | undefined>;
  getMRPPickErrorsForOrderQuery(orderId: string | undefined): UseQueryOptions<MrpPickErrorList>;
  getMRPPickErrorsQuery(command: DateCommand): UseQueryOptions<MrpPickErrorList>;
}

export const getErpSyncOrderQuery: ERPQueries['getErpSyncOrderQuery'] = (command) => ({
  queryKey: ['erp-sync-order', command],
  queryFn: async () => {
    if (!command) return undefined;
    const { data } = await monitorApi.apiErpErpSyncOrderGet({ query: command });
    return data;
  },
  enabled: !!command,
  refetchOnWindowFocus: false,
});

export const getErpSyncOrdersQuery: ERPQueries['getErpSyncOrdersQuery'] = (command) => ({
  queryKey: ['erp-sync-orders', command],
  queryFn: async () => {
    const { data } = await monitorApi.apiErpErpSyncOrdersGet(command);
    return data ?? [];
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  enabled: !!command,
});

export const getMRPSalesOrderQuery: ERPQueries['getMRPSalesOrderQuery'] = (cpproId) => ({
  queryKey: ['mrp-sales-order', cpproId],
  queryFn: async () => {
    if (!cpproId) return undefined;
    const { data } = await monitorApi.apiErpOrderCpproIdMrpSalesOrderGet({ cpproId });
    return data;
  },
  enabled: !!cpproId,
});

export const getMRPPickErrorsQuery: ERPQueries['getMRPPickErrorsQuery'] = (command) => ({
  queryKey: ['mrp-pick-errors', command],
  queryFn: async () => {
    const { data } = await monitorApi.apiErpMrpPickErrorsGet(command);
    return data.pickErrors as MrpPickErrorList;
  },
});

export const getMRPPickErrorsForOrderQuery: ERPQueries['getMRPPickErrorsForOrderQuery'] = (orderId) => ({
  queryKey: ['mrp-pick-errors-for-order', orderId],
  queryFn: async () => {
    if (!orderId) return [];
    const { data } = await monitorApi.apiErpMrpOrderOrderIdPickErrorsGet({ orderId });
    return data.pickErrors as MrpPickErrorList;
  },
  enabled: !!orderId,
});
