import type { MutationOptions, UseMutationOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Property } from '../../CPpro/model/model.js';
import { UserPropertyType } from '../../CPpro/model/model.js';
import type { AccessTypes } from '../types/index.js';
import type { CreateMamutRequest, DefaultApiApiCpproCreateorderOrderOrderidPostRequest, GenerateServiceOrderChecklistRequest } from '../../generated/activityhelper/api.js';
import { monitorApi } from '../apis/apis.js';

export const addUserRole: UseMutationOptions<unknown, Error, AccessTypes.UserAccessRoleProperty[]> = {
  mutationFn: (roles) => {
    const data: Property[] = roles.map((r) => ({
      id: 0,
      parent_id: r.userId,
      type: UserPropertyType.AccessRole,
      value: `${r.role}:${r.level}`,
    }));
    return axios.post('/userProperties', data);
  },
};

export const deleteUserRole: MutationOptions<unknown, Error, AccessTypes.UserAccessRoleProperty[]> = {
  mutationFn: (roles) => {
    const data: Property[] = roles.map((r) => ({
      id: r.id,
      parent_id: r.userId,
      type: UserPropertyType.AccessRole,
      value: `${r.role}:${r.level}`,
    }));
    return axios.delete('/userProperties', { data });
  },
};

export const updateUserRole: UseMutationOptions<unknown, Error, AccessTypes.UserAccessRoleProperty[]> = {
  mutationFn: (roles) => {
    const data: Property[] = roles.map((r) => ({
      id: r.id,
      parent_id: r.userId,
      type: UserPropertyType.AccessRole,
      value: `${r.role}:${r.level}`,
    }));
    return axios.put('/userProperties', data);
  },
};

export const generateServiceOrderChecklist: UseMutationOptions<string, AxiosError<string>, GenerateServiceOrderChecklistRequest> = {
  mutationFn: async (data) => {
    const response = await monitorApi.apiCpproOrderGenerateServiceChecklistPost({ generateServiceOrderChecklistRequest: data });
    return response.data;
  },
};

export const create24SOOrder: UseMutationOptions<void, AxiosError<string>, DefaultApiApiCpproCreateorderOrderOrderidPostRequest['orderid']> = {
  mutationFn: async (orderid) => {
    const response = await monitorApi.apiCpproCreateorderOrderOrderidPost({ orderid });
    return response.data;
  },
};

export const createMamutOrder: UseMutationOptions<unknown, AxiosError, CreateMamutRequest> = {
  mutationFn: async (data) => {
    const response = await monitorApi.apiCpproOrderCreateMamutPost({ createMamutRequest: data });
    return response.data;
  },
};
