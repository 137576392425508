export const copyTableToClipboard = (tableId: string, type?: 'body' | 'header') => {
  const table = document.getElementById(tableId);

  if (table) {
    const range = document.createRange();
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      let element: HTMLElement = table;
      if (type === 'body') {
        const tbody = table.getElementsByTagName('tbody');
        if (tbody && tbody.length > 0) {
          [element] = tbody;
        }
      }
      if (type === 'header') {
        const thead = table.getElementsByTagName('thead');
        if (thead && thead.length > 0) {
          [element] = thead;
        }
      }

      try {
        range.selectNodeContents(element);
        selection.addRange(range);
      } catch (e) {
        range.selectNode(element);
        selection.addRange(range);
      }

      navigator.clipboard.writeText(selection.toString());
      selection.removeAllRanges();
    }
  }
};

export const altCopyTableToClipboard = (tableId: string, type?: 'body' | 'header') => {
  const table = document.getElementById(tableId);

  if (table) {
    let tableHTMLString = table.outerHTML;
    let tableText = table.innerText;
    if (type === 'body') {
      const tbody = table.getElementsByTagName('tbody');
      if (tbody && tbody.length > 0) {
        tableHTMLString = tbody[0].outerHTML;
        tableText = tbody[0].innerText;
      }
    }
    if (type === 'header') {
      const thead = table.getElementsByTagName('thead');
      if (thead && thead.length > 0) {
        tableHTMLString = thead[0].outerHTML;
        tableText = thead[0].innerText;
      }
    }

    navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([tableHTMLString], {
          type: 'text/html',
        }),
        'text/plain': new Blob([tableText], {
          type: 'text/plain',
        }),
      }),
    ]);
  }
};

export const copyTextToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const downloadJSONFile = (jsonString: string, filename: string) => {
  const file = new File([jsonString], filename, {
    type: 'text/json',
  });

  const link = document.createElement('a');
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};
