import { Save, Search } from '@mui/icons-material';
import type { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import React from 'react';

type LoadingButtonProps = {
  save?: boolean;
  buttonProps?: Omit<MuiLoadingButtonProps, 'loadingPosition' | 'startIcon' | 'children'>
  children?: MuiLoadingButtonProps['children'];
  customIcon?: MuiLoadingButtonProps['startIcon'];
};

const LoadingButton = (props: LoadingButtonProps) => {
  const {
    save, buttonProps, children, customIcon,
  } = props;
  const icon = customIcon ?? (save ? <Save /> : <Search />);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    (<MuiLoadingButton {...buttonProps} variant={buttonProps?.variant || 'contained'} loadingPosition="start" startIcon={icon}>{children}</MuiLoadingButton>)
  );
};

export default LoadingButton;
