import React from 'react';
import { Box } from '@mui/material';
import type { NotificationChannelsResponseInner } from '../../../../generated/activityhelper/api.js';
import type { DialogRowElementProps } from '../../table/GenericTable.js';
import NotificationChannelForm from './NotificationChannelForm.js';

const NotificationChannelEditDialogContent = (props: DialogRowElementProps<NotificationChannelsResponseInner>) => {
  const { row, close } = props;
  if (!row) return null;
  return (
    <Box sx={{ marginTop: 1 }}>
      <NotificationChannelForm handleClose={close} edit={{ value: row }} />
    </Box>
  );
};

export default NotificationChannelEditDialogContent;
