import {
  Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import React, {
  useCallback, useEffect, useState,
} from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { MonitorQueries } from '../../../queries/index.js';
import GenericTable from '../../table/GenericTable.js';
import { MonitorTypes } from '../../monitor/index.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import useSearchQuery from '../../../hooks/useSearchQuery.js';
import MonitorLogsSearch from '../../monitor/search/MonitorLogsSearch.js';

const columnHelper = createColumnHelper<MonitorTypes.LogEntry<MonitorTypes.LogEntryErpError>>();

const columns = [
  columnHelper.accessor((row) => row.timestamp, {
    header: 'Date',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
    id: 'timestamp',
  }),
  columnHelper.accessor((row) => row.message, {
    header: 'Message',
    cell: (info) => info.getValue(),
    id: 'message',
  }),
  columnHelper.accessor((row) => row.severity, {
    header: 'Severity',
    cell: (info) => info.getValue(),
    id: 'severity',
  }),
];

const getOpenColumn = (onClick: (error: MonitorTypes.LogEntryErpError | undefined | null) => void) => columnHelper.accessor((row) => row.data, {
  header: 'Open',
  cell: (info) => (
    <Button variant="contained" onClick={() => onClick(info.row.original?.data)}>More Info</Button>
  ),
  id: 'open',
});

const MonitorErpPage = () => {
  const [filters, setFilters] = useState<Required<MonitorTypes.LogEntriesCommand>>(MonitorTypes.logDefaultData);
  const { queryData: data, query, refetch } = useSearchQuery(MonitorQueries.getErpErrorLogsQuery, MonitorTypes.logFixedDefaultData);
  const { data: logEntries, isLoading } = data;

  const [errorOpen, setErrorOpen] = useState(false);
  const [selectedError, setSelectedError] = useState<MonitorTypes.LogEntryErpError | undefined | null>(null);

  const openColumn = getOpenColumn(setSelectedError);

  useEffect(() => {
    if (selectedError) setErrorOpen(true);
  }, [selectedError]);

  const onSearch = useCallback(() => {
    const fixedFilters = {
      ...filters,
      ...DateUtils.fixDateCommand(filters),
    };

    refetch(fixedFilters);
  }, [filters, refetch]);

  return (
    <Box>
      <MonitorPageTitle title="ERP 24SO" refreshQuery={query} />
      <MonitorLogsSearch onSearch={onSearch} state={filters} setState={setFilters} loading={isLoading} />
      <Typography variant="h6" gutterBottom>
        Total errors:
        {' '}
        {logEntries?.length ?? 0}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Error logentries:
      </Typography>
      <GenericTable data={logEntries ?? []} columns={[...columns, openColumn]} />
      <Dialog maxWidth={false} fullWidth open={errorOpen} onClose={() => setErrorOpen(false)}>
        {selectedError ? (
          <>
            <DialogTitle>
              {selectedError.message}
              {' - Info'}
            </DialogTitle>
            <DialogContent>
              {/* TODO Autogenerate from metadata/data instead! */}
              <DialogContentText variant="h6">Metadata:</DialogContentText>
              <DialogContentText>
                <b>Response:</b>
              </DialogContentText>
              <code>
                {selectedError.metadata?.response}
              </code>
              <DialogContentText variant="h6">
                Error:
              </DialogContentText>
              <DialogContentText>
                <b>Message:</b>
                {' '}
                {selectedError.metadata?.error?.message}
              </DialogContentText>
              <DialogContentText>
                <b>Code:</b>
                {' '}
                {selectedError.metadata?.error?.code}
              </DialogContentText>
              <DialogContentText>
                <b>Stack:</b>
              </DialogContentText>
              <code>
                {selectedError.metadata?.error?.stack}
              </code>
            </DialogContent>
          </>
        ) : null }
      </Dialog>
    </Box>
  );
};

export default MonitorErpPage;
