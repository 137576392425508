import React from 'react';
import {
  Divider, Link, List, ListItem, ListItemIcon, ListItemText, TextField, Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import { Grid2 } from '@mui/material';
import { HorizontalRule } from '@mui/icons-material';
import useSearchQuery from '../../../hooks/useSearchQuery.js';
import { CPproQueries } from '../../../queries/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import type { GetOrderSearchRequest } from '../../../../generated/cppro/api.js';
import { getOrderLink } from '../../../../CPpro/Utils/commonUtils.js';
import { DateUtils } from '../../../../sharedutils/index.js';

const defaultData: GetOrderSearchRequest | undefined = undefined as GetOrderSearchRequest | undefined;

const CPProTools = () => {
  const [serialNumber, setSerialNumber] = React.useState('');
  const { queryData, refetch } = useSearchQuery(CPproQueries.getSearchOrdersQuery, defaultData);

  return (
    <Box>
      <MonitorPageTitle title="CPPro Verktøy" />
      <Grid2 container spacing={2}>
        <Grid2>
          <TextField
            title="Serienummer"
            label="Serienummer"
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
          />
        </Grid2>
        <Grid2>
          <LoadingButton buttonProps={{
            loading: queryData?.isLoading,
            onClick: () => refetch({ serialNumber }),
            disabled: !serialNumber,
          }}
          >
            Søk
          </LoadingButton>
        </Grid2>
      </Grid2>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="h6" gutterBottom>Ordre:</Typography>
        <List>
          {queryData.data?.orders.map((o) => (
            <ListItem key={o.id}>
              <ListItemIcon>
                <HorizontalRule />
              </ListItemIcon>
              <ListItemText
                primary={<Link href={getOrderLink(o.name)} target="_blank" rel="noreferrer noopener">{o.name}</Link>}
                secondary={(
                  <>
                    {o.answers.map((a) => (
                      <Typography>
                        {(a.value as string) ?? ''}
                        {a.model ? ` - ${a.model} - ` : ' - '}
                        {a.question}
                        {' - '}
                        {a.answeredBy}
                        {' - '}
                        {DateUtils.getLocalizedDateTime(a.date)}
                      </Typography>
                    ))}
                  </>
)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CPProTools;
