import type { Table } from '@tanstack/react-table';
import Papa from 'papaparse';
import { copyTextToClipboard } from './copyUtils.js';

interface CSVUtils {
  convertToCSV<T>(data: T[], headers?: string[], delimiter?: ';' | ','): string,
  downloadCSVFile(csvString: string, filename: string): void,
  getRowsAndHeadersFromTable<T extends object>(table: Table<T>, idHeader?: string, useTableHeaders?: boolean): { rows: T[], headers: string[] } | undefined,
  downloadTableAsCSV<T extends object>(table: Table<T>, fileName: string, idHeader?: string, useTableHeaders?: boolean): void,
  copyTableToClipboardAsCSV<T extends object>(table: Table<T>, idHeader?: string, useTableHeaders?: boolean): void,
}

export const convertToCSV: CSVUtils['convertToCSV'] = (data, headers, delimiter = ';') => {
  const headerCSV = headers ? Papa.unparse({ fields: headers, data: [] }, { header: true, delimiter }) : '';
  const contentCSV = Papa.unparse(data, { header: !headers, delimiter });
  return headerCSV + contentCSV;
};

export const downloadCSVFile = (csvString: string, filename: string) => {
  const file = new File([csvString], filename, {
    type: 'text/csv',
  });

  const link = document.createElement('a');
  const url = URL.createObjectURL(file);

  link.href = url;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const getRowsAndHeadersFromTable: CSVUtils['getRowsAndHeadersFromTable'] = (table, idHeader, useTableHeaders) => {
  // TODO Get cells instead?
  const rows = table.getFilteredRowModel().rows.map((r) => r.original);

  if (rows.length) {
    if (useTableHeaders) {
      const headerNames = table.getAllFlatColumns().map((c) => {
        const { header } = c.columnDef;
        if (typeof header === 'function') return '';
        return header ?? '';
      });
      if (headerNames.length) {
        return { rows, headers: idHeader ? [idHeader, ...headerNames] : headerNames };
      }
    }
    const first = rows[0];
    const headers = Object.keys(first);
    return { rows, headers };
  }
  return undefined;
};

export const downloadTableAsCSV: CSVUtils['downloadTableAsCSV'] = (table, fileName, idHeader, useTableHeaders) => {
  const data = getRowsAndHeadersFromTable(table, idHeader, useTableHeaders);
  if (data) {
    const csv = convertToCSV(data.rows, data.headers);

    downloadCSVFile(csv, fileName);
  }
};

export const copyTableToClipboardAsCSV: CSVUtils['copyTableToClipboardAsCSV'] = (table, idHeader, useTableHeaders) => {
  const data = getRowsAndHeadersFromTable(table, idHeader, useTableHeaders);
  if (data) {
    const csv = convertToCSV(data.rows, data.headers, ',');

    copyTextToClipboard(csv.replaceAll('\n', ' '));
  }
};
