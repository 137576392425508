import { Refresh } from '@mui/icons-material';
import {
  Divider, IconButton, Tooltip, Typography,
} from '@mui/material';
import { Grid2 } from '@mui/material';
import type { InvalidateQueryFilters } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback } from 'react';

type MonitorPageTitleProps = {
  refreshQuery?: InvalidateQueryFilters | InvalidateQueryFilters[],
  title: string,
};

const MonitorPageTitle = (props: MonitorPageTitleProps) => {
  const { refreshQuery, title } = props;
  const queryClient = useQueryClient();

  const invalidate = useCallback(() => {
    if (Array.isArray(refreshQuery)) {
      refreshQuery.forEach((query) => {
        queryClient.invalidateQueries(query);
      });
    } else {
      queryClient.invalidateQueries(refreshQuery);
    }
  }, [refreshQuery, queryClient]);

  return (
    <>
      <Grid2
        container
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Grid2>
          <Typography variant="h4" gutterBottom>{title}</Typography>
        </Grid2>
        {refreshQuery ? (
          <Grid2>
            <Tooltip title="Refresh data">
              <IconButton color="success" onClick={invalidate}>
                <Refresh />
              </IconButton>
            </Tooltip>
          </Grid2>
        ) : null}
      </Grid2>
      <Divider sx={{ mb: 2 }} />
    </>
  );
};

export default MonitorPageTitle;
