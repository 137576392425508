import type {
  AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig,
} from 'axios';
import axios from 'axios';
import { setNotification } from '../../CPpro/Utils/localstorage/notification.js';
import { getData } from '../../CPpro/Utils/storage.js';
import { errorToast } from '../../CPpro/Utils/toast.js';
import 'toastify-js/src/toastify.css';

interface AxiosResponseInterceptor {
  (response: AxiosResponse): void;
}

// Handle 401 and 403 from api
export const axiosResponseInterceptor: AxiosResponseInterceptor = (response) => {
  if (response.status === 401 || response.status === 403) {
    const body = response.data as string;
    if (body === 'Token expired' || body === 'User not found') {
      // For report
      if (import.meta.env.VITE_FRONTEND_URL !== window.location.origin) {
        if (!window.location.pathname.startsWith('/login')) {
          window.location.replace(`${window.location.origin}/login`);
        }
      } else {
        if (window.location.origin === import.meta.env.VITE_FRONTEND_URL) {
          setNotification({ text: body, type: 'warning' });
        }
        window.location.replace(import.meta.env.VITE_FRONTEND_URL ?? window.location.origin);
      }
    }
  }

  if (response.status >= 400) {
    errorToast(`${response.status} | Server error: ${JSON.stringify(response.data)}`, true);
  }
};

// Add token to all requests
// eslint-disable-next-line @typescript-eslint/no-explicit-any, max-len
export const axiosRequestInterceptor: (value: InternalAxiosRequestConfig<any>) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> = async (config) => {
  const token = await getData('bearer');
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    // For report (cannot use cppro login)
  } else if (import.meta.env.VITE_FRONTEND_URL !== window.location.origin) {
    if (!window.location.pathname.startsWith('/login')) {
      window.location.replace(`${window.location.origin}/login`);
    }
  } else {
    if (window.location.origin === import.meta.env.VITE_FRONTEND_URL) {
      setNotification({ text: 'Token not found - please log in', type: 'warning' });
    }
    window.location.replace(import.meta.env.VITE_FRONTEND_URL ?? window.location.origin);
  }
  return config;
};

export const createAxiosInstance = (baseURL: string | undefined, axiosStatic?: AxiosInstance): AxiosInstance => {
  const instance = axiosStatic || axios.create();

  instance.defaults.baseURL = baseURL;
  instance.interceptors.request.use(axiosRequestInterceptor);
  instance.interceptors.response.use((response) => {
    axiosResponseInterceptor(response);

    return response;
  }, (err) => {
    const error = err as AxiosError;
    if (error.response) {
      axiosResponseInterceptor(error.response);
      return Promise.reject(err);
      // return error.response;
    }
    return Promise.reject(new Error(err));
  });

  return instance;
};
