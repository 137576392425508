import { createContext, useContext } from 'react';
import type { UserData } from '../providers/CpproUserProvider.js';

export interface ICpproUserContext {
  user: UserData | null,
  refreshUser(): void,
  refetchAccessLeves(): void,
  isLoading: boolean,
}

const defaultCpproUserContext: ICpproUserContext = {
  user: null,
  refreshUser: () => {},
  refetchAccessLeves: () => {},
  isLoading: false,
};

const CpproUserContext = createContext<ICpproUserContext>(defaultCpproUserContext);

export default CpproUserContext;

export const useCPproUserContext = () => useContext(CpproUserContext);
