import {
  Button, Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import { MonitorQueries } from '../../../queries/index.js';
import type { AlertPoliciesResponseInner } from '../../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import GenericTable from '../../table/GenericTable.js';

const policyColumnHelper = createColumnHelper<AlertPoliciesResponseInner>();

const policyColumns = [
  policyColumnHelper.accessor((r) => r.name, {
    header: 'Name',
    id: 'name',
  }),
  policyColumnHelper.accessor((r) => r.displayName, {
    header: 'Display Name',
    id: 'displayName',
  }),
  policyColumnHelper.accessor((r) => r.enabled, {
    header: 'Enabled',
    id: 'enabled',
  }),
  policyColumnHelper.accessor((r) => r.created, {
    header: 'Created',
    id: 'created',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  policyColumnHelper.accessor((r) => r.modified, {
    header: 'Modified',
    id: 'modified',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  policyColumnHelper.accessor((r) => r.conditions, {
    header: 'Conditions',
    id: 'conditions',
    cell: (info) => <Button onClick={() => info.table.options.meta?.openDataDialog(info.getValue())}>Open dialog</Button>,
  }),
  policyColumnHelper.accessor((r) => r.documentation, {
    header: 'Documentation',
    id: 'documentation',
  }),
  policyColumnHelper.accessor((r) => r.validity, {
    header: 'Validity',
    id: 'validity',
  }),
  policyColumnHelper.accessor((r) => r.severity, {
    header: 'Severity',
    id: 'severity',
  }),
  policyColumnHelper.accessor((r) => r.notificationRateLimit, {
    header: 'Notification Rate Limit',
    id: 'notificationRateLimit',
  }),
  policyColumnHelper.accessor((r) => r.autoClose, {
    header: 'Auto Close',
    id: 'autoClose',
  }),
];

const dialogContent = (data: unknown | undefined) => (
  <pre><code>{JSON.stringify(data ?? {}, undefined, 2)}</code></pre>
);

const MonitorPolicyPage = () => {
  const policies = useQuery(MonitorQueries.policiesQuery);

  return (
    <Box>
      <MonitorPageTitle title="Incidents" refreshQuery={MonitorQueries.policiesQuery} />
      <Typography variant="h6" gutterBottom>Policies</Typography>
      <GenericTable
        id="policies"
        columns={policyColumns}
        data={policies.data ?? []}
        pagination
        dataDialogProps={{
          title: 'Policy conditions',
          Content: dialogContent,
          maxWidth: 'xl',
        }}
      />
    </Box>
  );
};

export default MonitorPolicyPage;
