import {
  Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { MonitorQueries } from '../../../queries/index.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import { MonitorTypes } from '../../monitor/index.js';
import { DateUtils } from '../../../../sharedutils/index.js';
import GenericTable from '../../table/GenericTable.js';
import useSearchQuery from '../../../hooks/useSearchQuery.js';
import MonitorEmailSMSSearch from '../../monitor/search/MonitorEmailSMSSearch.js';

const columnHelper = createColumnHelper<MonitorTypes.Sms>();

const columns = [
  // columnHelper.accessor((row) => row.subject, {
  //   header: 'Subject',
  //   id: 'subject',
  // }),
  columnHelper.accessor((row) => row.to, {
    header: 'To',
    id: 'to',
  }),
  columnHelper.accessor((row) => row.type, {
    header: 'Type',
    id: 'type',
  }),
  columnHelper.accessor((row) => row.from, {
    header: 'From',
    id: 'from',
  }),
  columnHelper.accessor((row) => row.sent, {
    header: 'Sent',
    id: 'sent',
    cell: (row) => DateUtils.getLocalizedDateTime(row.getValue()),
  }),
  columnHelper.accessor((row) => row.status, {
    header: 'Status',
    id: 'status',
  }),
  columnHelper.accessor((row) => row.retries, {
    header: 'Retries',
    id: 'retries',
  }),
  columnHelper.accessor((row) => row.created, {
    header: 'Created',
    id: 'created',
    cell: (row) => DateUtils.getLocalizedDateTime(row.getValue()),
  }),
  columnHelper.accessor((row) => row.error, {
    header: 'Error',
    id: 'error',
  }),
];

const MonitorSmsPage = () => {
  const [filters, setFilters] = useState<Required<MonitorTypes.GetSMSesCommand>>(MonitorTypes.emailSMSDefaultData);
  const { queryData, query, refetch } = useSearchQuery(MonitorQueries.getSMSesForMetricsQuery, MonitorTypes.emailSMSFixedDefaultData);
  const { data: smses, isLoading } = queryData;

  const metrics = useMemo(() => {
    let notSent = 0;
    let moreThanOneRetry = 0;

    for (const sms of smses ?? []) {
      if (sms.status !== 'SENT') {
        notSent += 1;
      }
      if (sms.retries > 1) {
        moreThanOneRetry += 1;
      }
    }

    return { notSent, moreThanOneRetry };
  }, [smses]);

  const onSearch = useCallback(() => {
    const fixedFilters = MonitorTypes.fixEmailSMSCommand({ ...filters, ...DateUtils.fixDateCommand(filters) });

    refetch(fixedFilters);
  }, [filters, refetch]);

  return (
    (
      <Box>
        <MonitorPageTitle title="SMS" refreshQuery={query} />
        <MonitorEmailSMSSearch state={filters} setState={setFilters} onSearch={onSearch} loading={isLoading} />
        <Typography variant="h6">
          SMSes with not sent status:
          {' '}
          {metrics.notSent}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
          }}
        >
          SMSes with more than one retry:
          {' '}
          {metrics.moreThanOneRetry}
        </Typography>
        <GenericTable columns={columns} data={smses ?? []} pagination />
      </Box>
    )
  );
};

export default MonitorSmsPage;
