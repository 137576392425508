import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getCompleteSalesOrdersQuery, productsQuery } from '../queries/cpproQueries.js';
import type {
  CompleteSalesOrder,
  CompleteSalesOrderRequest, DrizzleAnswer, DrizzleProperty, SalesRow, SalesRowExtended, SalesRowRequired,
} from '../types.js';
import { getSalesRowOrderLines, saleTypes } from '../types.js';
import type { Product, ProductPropertyCustomValue } from '../../CPpro/model/model.js';
import {
  AnswerPropertyType, OrderPropertyType, ProductPropertyType,
} from '../../CPpro/model/model.js';
import { calculateFinalProductPrice, removeNulls } from '../../CPpro/Utils/tools.js';

// TODO CREATED IS A DATE HERE!!!

const sortCreatedDesc = <T extends { created?: string },>(a: T, b: T) => {
  if (!a.created && b.created) return -1;
  if (a.created && !b.created) return 1;
  if (!a.created || !b.created) return 0;

  if (typeof a.created === 'number' && typeof b.created === 'number') return b.created - a.created;

  const aDate = new Date(a.created.toString().trim());
  const bDate = new Date(b.created.toString().trim());

  return bDate.getTime() - aDate.getTime();
};

const convertOrderNameToDateAndName = (nameProp: DrizzleProperty | undefined): { adviser: string, date: Date } | undefined => {
  if (!nameProp) return undefined;

  const name = nameProp.strvalue?.toString().trim() ?? '';
  if (!name || !name.includes('@')) return undefined;

  let date: Date | string = '';
  if (nameProp.created) {
    const created = nameProp.created.endsWith('Z') ? nameProp.created : `${nameProp.created}Z`;
    date = new Date(created);
    // if (Number.isNaN(date)) date = created;
  } else if (name.length > 14) {
    const dateString = name.substring(name.length - 14).replace(/^(\d\d\d\d)(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, '$1-$2-$3T$4:$5:$6');
    date = new Date(dateString);
    // if (Number.isNaN(date)) date = dateString;
  } else {
    return undefined;
  }
  return { adviser: name.slice(0, -14), date };
};

const getOrderStatus = (order: CompleteSalesOrder): SalesRow['status'] => {
  const statuses = order.properties.filter((p) => p.PropertyType_id === OrderPropertyType.Status);

  // If more than one status, prioritize OB then TS

  // OB
  if (statuses.find((p) => p.strvalue?.toString().trim() === 'OB')) return 'OB';

  // TS
  if (statuses.find((p) => p.strvalue?.toString().trim() === 'TS')) return 'TS';

  return statuses.find(() => true)?.strvalue?.toString().trim() as SalesRow['status'];
};

const getAnswer = (order: CompleteSalesOrder, questionId: number, getOldest?: boolean) => {
  const answers = order.item.flatMap((i) => i.answer?.filter((a) => a.parent_id === questionId) ?? []);

  // TODO Sort?
  const answer = answers?.toSorted((a, b) => (getOldest ? sortCreatedDesc(b, a) : sortCreatedDesc(a, b))).find(() => true);

  const answerProp = answer?.properties.find((p) => p.PropertyType_id === AnswerPropertyType.Answer);

  // TODO Filter/sort

  return answer ? {
    answer: answerProp?.strvalue ?? answerProp?.numvalue,
    answeredBy: answer.properties.find((p) => p.PropertyType_id === AnswerPropertyType.AnsweredBy)?.strvalue?.trim(),
    created: answer.created,
  } : undefined;
};

const getAnswers = (
  order: CompleteSalesOrder,
  questionId: number,
  sort?: 'desc' | 'asc',
) => {
  const answers = order.item.flatMap((i) => i.answer?.filter((a) => a.parent_id === questionId) ?? []);

  const sorted = sort ? answers.toSorted((a, b) => (sort === 'desc' ? sortCreatedDesc(a, b) : sortCreatedDesc(b, a))) : answers;

  // Filter so only distinct values are returned!!!
  const filtered = sorted.filter((a, i) => sorted.findIndex((s) => s.id === a.id) === i);

  // if (order.id === 35378 && questionId === 1487) {
  //   console.log(answers);
  //   console.log(sorted);
  //   console.log(filtered);
  // }

  return filtered;
};

const mapGetAnswers = (answers: ReturnType<typeof getAnswers>) => answers.map((a) => {
  const answerProp = a.properties.find((p) => p.PropertyType_id === AnswerPropertyType.Answer);

  return {
    answer: answerProp?.strvalue ?? answerProp?.numvalue,
    answeredBy: a.properties.find((p) => p.PropertyType_id === AnswerPropertyType.AnsweredBy)?.strvalue?.trim(),
    created: a.created,
  };
});

const getAnswerWithChecklistId = (
  order: CompleteSalesOrder,
  checklistId: number,
) => {
  const answers = order.item.flatMap((i) => i.answer?.filter((a) => a.checklist_id === checklistId) ?? []);

  return answers
    .toSorted(sortCreatedDesc)
    .find(() => true);
};

const removeDuplicateOrderLinesAndSort = (data: ReturnType<typeof getAnswers>) => {
  const final: ReturnType<typeof getAnswers> = [];
  const seqNoMap: Record<string, ReturnType<typeof getAnswers> | undefined> = {};

  // console.log('in', data);

  for (const answer of data) {
    const seqNo = answer.seq_no;
    if (seqNo) {
      if (seqNoMap[seqNo]) seqNoMap[seqNo]?.push(answer);
      else seqNoMap[seqNo] = [answer];
    } else {
      final.push(answer);
    }
  }

  // for (const item of data) {
  //   if (item.seq_no) {
  //     if (seqNoMap[item.seq_no]) seqNoMap[item.seq_no]?.push(item);
  //     else seqNoMap[item.seq_no] = [item];
  //   } else {
  //     final.push(item);
  //   }
  // }

  const distinct = Object.values(seqNoMap).map((dupes) => {
    if (dupes) return dupes.toSorted((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()).find(() => true);
    return undefined;
  }).filter(removeNulls);

  const result = final.concat(distinct).toSorted((a, b) => {
    const aSeqNo = a.seq_no;
    const bSeqNo = b.seq_no;
    if (aSeqNo === bSeqNo) {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    }
    return aSeqNo - bSeqNo;
  });
  // console.log('result', result);
  return result;
};

const getQuoteLinks = (
  order: CompleteSalesOrder,
) => {
  const answers = getAnswers(order, 1487, 'desc');
  // if (order.id === 35378) console.log(answers);
  return answers.map((a) => {
    const answer = a.properties.find((p) => p.PropertyType_id === AnswerPropertyType.Answer);
    const value = answer?.strvalue?.trim();
    // Filter more (check that type = 5 has correct json object??)
    if (value && value.endsWith('.pdf')) {
      return value;
    }
    return null;
  }).filter(removeNulls) ?? [];
};

const getFullSalesRow = (
  order: CompleteSalesOrder,
  installOrder: CompleteSalesOrder | undefined,
  products: Product[],
  required: SalesRowRequired,
) => {
  // Find all answers that match item ids in the order for salesdata and orderdata:

  const sevenOfficeOrderIdAnswers = getAnswers(order, 1487);
  let sevenOfficeOrderId: number | undefined = undefined;
  let sevenOfficeOrderIdAnswer: DrizzleAnswer | undefined = undefined;

  if (sevenOfficeOrderIdAnswers.length) {
    sevenOfficeOrderIdAnswer = sevenOfficeOrderIdAnswers.find((a) => a.properties.some((p) => p.PropertyType_id === AnswerPropertyType.CustomQuestionProperties && p.strvalue?.includes('24SO_AC_OrderId')));
    sevenOfficeOrderId = sevenOfficeOrderIdAnswer?.properties.find((p) => p.PropertyType_id === AnswerPropertyType.Answer)?.numvalue || undefined;
  }

  // q1479 - mamutorder
  const mamutOrderAnswer = getAnswer(order, 1479);
  const oldMamutOrderAnswers = mapGetAnswers(getAnswers(order, 1479, 'asc'));

  // Find oldest answer with same answer as newest
  const oldMamutOrderAnswer = oldMamutOrderAnswers.find((a) => a.answer === mamutOrderAnswer?.answer);
  // if (order.id === 35363) {
  //   console.log(mamutOrderAnswer);
  //   console.log(oldMamutOrderAnswer);
  // }

  // q1201 - meter pipe adviser
  const meterPipeAdviserAnswer = getAnswer(order, 1201);

  // q1017 - notes for installer
  // if (order.id === 36456) {
  //   const notes = getAnswers(order, 1017);
  //   console.log(notes);
  // }
  const notesForInstallerAnswer = getAnswer(order, 1017);

  // q1385 meter pipe installer
  const meterPipeInstallerAnswer = installOrder ? getAnswer(installOrder, 1385) : undefined;

  // q1269 - sale rating
  const saleRatingAnswer = installOrder ? getAnswer(installOrder, 1269) : undefined;

  // checklist 82 - resursbank
  const resursbankAnswer = getAnswerWithChecklistId(order, 82);

  // q1019 - agreed price (???)
  const agreedPriceAnswer = getAnswer(order, 1019)?.answer?.toString();

  // q1032 - original price (???)
  const originalPriceAnswer = getAnswer(order, 1032)?.answer?.toString();

  // if (agreedPriceAnswer || originalPriceAnswer) {
  //   console.log(agreedPriceAnswer);
  //   console.log(originalPriceAnswer);
  // }

  // q1049 - pumpe change (1/0 yes/no)
  const pumpChange = getAnswer(order, 1049)?.answer?.toString();

  // Product lines
  const productLineProducts: { name: string, price: number, originalPrice: number }[] = [];
  const productLineAnswers = removeDuplicateOrderLinesAndSort(getAnswers(order, 1481));

  for (const answer of productLineAnswers) {
    try {
      const answerProp = answer.properties.find((p) => p.PropertyType_id === AnswerPropertyType.Answer);
      if (answerProp && answerProp.strvalue) {
        const productData = JSON.parse(answerProp.strvalue.toString()) as { id: number, qty: number, discount?: number };
        const product = products.find((p) => p.id === productData.id);
        if (product) {
          let productName = product.properties.find((p) => p.type === ProductPropertyType.Name)?.value.toString() ?? '';
          let price = parseInt(product.properties.find((p) => p.type === ProductPropertyType.PriceExVAT)?.value.toString() ?? '0', 10);
          const vat = (product.properties.find((p) => p.type === ProductPropertyType.VAT)?.value as number || 25) + 100;

          const customProduct = product.properties.find((p) => p.type === ProductPropertyType.Custom);
          if (customProduct) {
            try {
              const value = JSON.parse(customProduct.value.toString()) as ProductPropertyCustomValue;
              const textId = value.text === 'billableExtraWorkText' ? 1532 : undefined;
              const priceId = value.price === 'billableExtraWorkPrice' ? 1533 : undefined;
              if (textId) productName = getAnswer(order, textId)?.answer?.toString() ?? '';
              if (priceId) price = parseInt(getAnswer(order, priceId)?.answer?.toString() ?? '0', 10);
            } catch {
              // empty
            }
          }

          if (price > 0) {
            const { costAfterDiscount, originalCost } = calculateFinalProductPrice(
              price,
              vat,
              productData.qty,
              productData.discount,
              !!customProduct,
            );
            productLineProducts.push({ name: productName, price: costAfterDiscount, originalPrice: originalCost });
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // TODO MISSING
  // q??? - score from customer - does not exist yet
  // console.log(productLineProducts);

  const price = agreedPriceAnswer
    ? parseInt(agreedPriceAnswer, 10)
    : productLineProducts.reduce((total, p) => total + p.price, 0);
  const originalPrice = originalPriceAnswer
    ? parseInt(originalPriceAnswer, 10)
    : productLineProducts.reduce((total, p) => total + p.originalPrice, 0);

  let date: Date;
  if (required.status === 'OB') {
    if (mamutOrderAnswer?.answer?.toString() && oldMamutOrderAnswer?.created) {
      date = new Date(oldMamutOrderAnswer.created);
    } else if (sevenOfficeOrderIdAnswer) {
      date = new Date(sevenOfficeOrderIdAnswer.created);
    } else {
      date = required.date;
    }
  } else {
    date = required.date;
  }

  const result: SalesRowExtended = {
    originalPrice,
    price,
    mamutOrderNr: mamutOrderAnswer?.answer?.toString(),
    db: undefined,
    dg: undefined,
    totalOrderLines: productLineProducts.length,
    ...getSalesRowOrderLines(productLineProducts.toSorted((a, b) => b.price - a.price).map((p) => p.name)),
    pipeMetersAdviser: meterPipeAdviserAnswer?.answer ? Number(meterPipeAdviserAnswer?.answer) : undefined,
    pipeMetersInstaller: meterPipeInstallerAnswer?.answer ? Number(meterPipeInstallerAnswer?.answer) : undefined,
    pipeDifference: meterPipeAdviserAnswer?.answer && meterPipeInstallerAnswer?.answer
      ? Number(meterPipeAdviserAnswer?.answer) - Number(meterPipeInstallerAnswer?.answer)
      : undefined,
    resursBank: !!resursbankAnswer,
    saleRating: saleRatingAnswer?.answer?.toString(),
    saleRatingAnsweredBy: saleRatingAnswer?.answeredBy?.toString(),
    customerRating: undefined,
    notesForInstaller: notesForInstallerAnswer?.answer?.toString(),
    pumpChange,
    sevenOfficeOrderId,
  };

  return { extended: result, date };
};

const removeDuplicateAnswerIDs = (order: CompleteSalesOrder) => {
  const answerIDs: number[] = [];
  for (const item of order.item) {
    if (item.answer) {
      for (const answer of item.answer) {
        if (answerIDs.indexOf(answer.id) === -1) { //
          answerIDs.push(answer.id);
        } else {
          // remove answer from question
          item.answer = item.answer.filter((a) => a.id !== answer.id);
        }
      }
    }
  }
};

type UseNewSalesDataProps = {
  request: CompleteSalesOrderRequest;
};

const useNewSalesData = (props: UseNewSalesDataProps) => {
  const { request } = props;
  const query = useMemo(() => getCompleteSalesOrdersQuery(request), [request]);
  const { data: completeSalesOrders, isLoading: ordersIsLoading } = useQuery(query);
  const { data: products, isLoading: productsIsLoading } = useQuery(productsQuery);

  const isLoading = useMemo<boolean>(
    () => ordersIsLoading
      || productsIsLoading,
    [ordersIsLoading, productsIsLoading],
  );

  const data = useMemo<SalesRow[]>(() => {
    if (completeSalesOrders && products) {
      const salesRows: SalesRow[] = [];

      for (const o of completeSalesOrders) {
        // TODO?
        removeDuplicateAnswerIDs(o);
        // Get required data
        const orderNameProperty = o.properties.find((p) => p.PropertyType_id === OrderPropertyType.Name);
        // Use filter and find the newest one (can be multiple!)
        const customerNames = o.properties.filter((p) => p.PropertyType_id === OrderPropertyType.CustomerName);
        const customerName = customerNames.toSorted(sortCreatedDesc).find(() => true);
        const questionName = getAnswer(o, 25)?.answer?.toString().trim();

        const actualName = customerName?.strvalue?.trim() ?? questionName;
        const adviserAndDate = convertOrderNameToDateAndName(orderNameProperty);
        let status = getOrderStatus(o);

        // Find order number of the install order! (same as mamut order number)

        const mamutOrderAnswer = getAnswer(o, 1479);

        let installOrder: CompleteSalesOrder | undefined = undefined;
        if (mamutOrderAnswer?.answer) {
          installOrder = completeSalesOrders.find((so) => !!so.properties.find(
            (p) => p.PropertyType_id === OrderPropertyType.Name
            && (p.numvalue?.toString() === mamutOrderAnswer.answer?.toString() || p.strvalue === mamutOrderAnswer.answer?.toString()),
          ));
        }

        const orderLost = getAnswer(o, 1034);
        if (orderLost?.answer === '1' || orderLost?.answer === 1) status = 'Tapt';

        const quotes = getQuoteLinks(o);

        // TODO Might need to check question in checklist/product instead???
        const orderTypeAnswer = getAnswer(o, 1576);
        const serviceCreateOrderAnswer = getAnswer(o, 1571);

        // Return null if missing required data^
        if (!serviceCreateOrderAnswer
          && !orderTypeAnswer
          && orderNameProperty
          && adviserAndDate
          && actualName
          && saleTypes.includes(status) // comment this line to test when no TS / Tapt / OB actually exists!
        ) {
          const customerProp = o.properties.find((p) => p.PropertyType_id === OrderPropertyType.CustomerPhone);
          const customerPhone = customerProp?.strvalue?.trim() ?? customerProp?.numvalue?.toString().trim();
          const questionPhone = getAnswer(o, 29)?.answer?.toString().trim();

          const required: SalesRowRequired = {
            id: o.id,
            status,
            date: adviserAndDate.date,
            customerName: actualName,
            customerPhone: customerPhone ?? questionPhone ?? '',
            adviser: adviserAndDate.adviser,
            orderName: orderNameProperty.strvalue?.toString().trim() ?? '',
          };

          if (status === 'Tapt') {
            salesRows.push(required);
          } else {
            const { extended, date } = getFullSalesRow(o, installOrder, products, required);
            required.date = date;
            if (status === 'OB' && (!!extended.mamutOrderNr || !!extended.sevenOfficeOrderId)) {
              salesRows.push({ ...required, quoteLink: undefined, ...extended });
            } else {
              required.status = 'TS';
              for (const quote of quotes) {
                salesRows.push({
                  ...required, quoteLink: quote, ...extended,
                });
              }
            }
          }
        }
      }

      return salesRows;
    }
    return [];
  }, [completeSalesOrders, products]);

  return { data, isLoading };
};

export default useNewSalesData;

// TODO ISSUES STILL REMAINING:
// Some dates are +- 1 hour (timezone issues?)
// Some offers are MISSING!
// Price is incorrect!!! (always the same as original price!!!)
