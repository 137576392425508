import { TextField } from '@mui/material';
import { Grid2 } from '@mui/material';
import React from 'react';

type MonitorDateSearchProps = {
  start: string,
  setStart: (start: string) => void,
  end: string,
  setEnd: (end: string) => void
};

const MonitorDateSearch = (props: MonitorDateSearchProps) => {
  const {
    start, end, setStart, setEnd,
  } = props;
  return [
    <Grid2 key="start">
      <TextField label="Start" type="datetime-local" value={start} onChange={(e) => setStart(e.target.value)} />
    </Grid2>,
    <Grid2 key="end">
      <TextField label="End" type="datetime-local" value={end} onChange={(e) => setEnd(e.target.value)} />
    </Grid2>,
  ];
};

export default MonitorDateSearch;
