// For when we deploy report in production (its own instance/service)

// Login to google with google login react component (???)
// Get token
// Send token to backend
// Save token in bearer + bearertime?? (indexeddb)

import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import type { CredentialResponse } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { User } from '../../../CPpro/model/model.js';
import { setData } from '../../../CPpro/Utils/storage.js';
import CpproUserContext from '../../contexts/CpproUserContext.js';

const uninterceptedAxiosInstance = axios.create();

const LoginPage = () => {
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const { refreshUser, refetchAccessLeves } = useContext(CpproUserContext);

  const onSuccess = async (response: CredentialResponse) => {
    const { data, status, statusText } = await uninterceptedAxiosInstance.request<[User[], { newtoken: string }]>({
      url: `${import.meta.env.VITE_API_URL}/useremail`,
      validateStatus: null,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${response.credential}`,
      },
    });

    if (status === 200 && data && data[0] && data[0][0]) {
      // const [[user]] = data;
      // TODO check user group???
      const token = data[1].newtoken;
      await setData('bearer', token);
      refreshUser();
      refetchAccessLeves();
      navigate('/');
    } else {
      setError(statusText);
    }
  };

  return (
    (
      <Box>
        <Typography variant="h2" gutterBottom>Login for CPPRO Reports</Typography>
        <GoogleLogin use_fedcm_for_prompt onSuccess={onSuccess} />
        {error ? (
          <Typography
            variant="h6"
            sx={{
              color: 'error',
              marginTop: 2,
            }}
          >
            {error}
          </Typography>
        ) : null}
      </Box>
    )
  );
};

export default LoginPage;
