// export const AccessRoles = ['MONITOR'] as const;

export const AccessRoles = {
  MONITOR: 'MONITOR',
  ERP: 'ERP',
} as const;

// export type AccessRole = typeof AccessRoles[number];
export type AccessRole = keyof typeof AccessRoles;

// export const AccessRoleLevels = [
//   'READ',
//   'WRITE',
//   'ADMIN',
// ] as const;

export const AccessRoleLevels = {
  NONE: '',
  READ: 'READ',
  WRITE: 'WRITE',
  ADMIN: 'ADMIN',
} as const;

export const AccessRoleLevelMap = {
  NONE: 0,
  READ: 1,
  WRITE: 2,
  ADMIN: 3,
} as const;

export const ReadArray = [AccessRoleLevels.READ, AccessRoleLevels.WRITE, AccessRoleLevels.ADMIN] as string[];
export const WriteArray = [AccessRoleLevels.WRITE, AccessRoleLevels.ADMIN] as string[];

// export type AccessRoleLevel = typeof AccessRoleLevels[number] | '';
export type AccessRoleLevel = keyof typeof AccessRoleLevels | '';

export type UserAccessRoleProperty = { role: AccessRole; level: AccessRoleLevel, userId: number, id: number };
export type UserAccessRole = Pick<UserAccessRoleProperty, 'role' | 'level'>;

export const roleLevelToNumber = (level: AccessRoleLevel) => AccessRoleLevelMap[level];
export const compareRoleLevels = (
  current: AccessRoleLevel,
  levelToCompareTo: AccessRoleLevel,
) => (roleLevelToNumber(current) - roleLevelToNumber(levelToCompareTo)) >= 0;
