import React from 'react';
import { useForm } from '@tanstack/react-form';
import {
  Divider, FormControl, InputLabel, Link, MenuItem, Select, TextField,
} from '@mui/material';
import { Box } from '@mui/material';
import { Grid2 } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { GenerateServiceOrderChecklistRequestOrderTypeEnum, type GenerateServiceOrderChecklistRequest } from '../../../generated/activityhelper/api.js';
import { CPproMutations } from '../../mutations/index.js';
import LoadingButton from '../common/buttons/LoadingButton.js';

type Props = {
  defaultData: GenerateServiceOrderChecklistRequest,
};

const CreateServiceChecklistForm = (props: Props) => {
  const { defaultData } = props;
  const [orderLink, setOrderLink] = React.useState<string | null>(null);

  const mutation = useMutation(CPproMutations.generateServiceOrderChecklist);
  const create24SOOrderMutation = useMutation(CPproMutations.create24SOOrder);

  const form = useForm<GenerateServiceOrderChecklistRequest>({
    defaultValues: defaultData,
    onSubmit: async ({ value }) => {
      const mapped: GenerateServiceOrderChecklistRequest = {
        ...value,
        calendar: value.calendar || undefined,
        dateAndClock: value.dateAndClock || undefined,
        serviceText: value.serviceText || undefined,
        slotLength: value.slotLength || undefined,
        orderType: value.orderType || undefined,
      };
      const response = await mutation.mutateAsync(mapped);
      setOrderLink(response.toString());
    },
  });

  return (
    <Box sx={{ marginBottom: 2 }}>
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2 container spacing={1} direction="column">
          <Grid2>
            <form.Field name="customer.name">
              {(field) => (
                <TextField
                  label="Navn"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="customer.phone">
              {(field) => (
                <TextField
                  label="Telefonnummer"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="customer.email">
              {(field) => (
                <TextField
                  label="Epost"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="customer.address">
              {(field) => (
                <TextField
                  label="Adresse"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="customer.zip">
              {(field) => (
                <TextField
                  label="Postnummer"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="customer.city">
              {(field) => (
                <TextField
                  label="Sted"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="calendar">
              {(field) => (
                <TextField
                  label="Kalender"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="dateAndClock">
              {(field) => (
                <DateTimePicker
                  ampm={false}
                  label="Dato og klokke"
                  value={field.state.value ? dayjs(field.state.value) : null}
                  onChange={(e) => field.handleChange(e?.toISOString() || undefined)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="slotLength">
              {(field) => (
                <FormControl>
                  <InputLabel id="select_slotlength_label" shrink sx={{ backgroundColor: 'white' }}>
                    Slotlength
                  </InputLabel>
                  <Select
                    label="Slotlength"
                    labelId="select_slotlength_label"
                    displayEmpty
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    fullWidth
                    sx={{ width: 100 }}
                  >
                    <MenuItem value="00:30">00:30</MenuItem>
                    <MenuItem value="01:00">01:00</MenuItem>
                    <MenuItem value="01:30">01:30</MenuItem>
                    <MenuItem value="02:00">02:00</MenuItem>
                    <MenuItem value="02:30">02:30</MenuItem>
                  </Select>
                </FormControl>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="serviceText">
              {(field) => (
                <TextField
                  label="Servicetekst"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="orderType">
              {(field) => (
                <FormControl>
                  <InputLabel id="select_type_label" shrink sx={{ backgroundColor: 'white' }}>
                    Ordretype
                  </InputLabel>
                  <Select
                    labelId="select_type_label"
                    displayEmpty
                    label="Ordretype"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value as GenerateServiceOrderChecklistRequestOrderTypeEnum || undefined)}
                    fullWidth
                    sx={{ width: 200 }}
                  >
                    {Object.values(GenerateServiceOrderChecklistRequestOrderTypeEnum).map((v) => (
                      <MenuItem key={v} value={v}>{v}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="advisorEmail">
              {(field) => (
                <TextField
                  label="Epost rådgiver (blir navnet på ordren)"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
              {([canSubmit, isSubmitting]) => (
                <LoadingButton
                  save
                  buttonProps={{
                    variant: 'contained',
                    type: 'submit',
                    loading: isSubmitting,
                    disabled: !canSubmit || isSubmitting,
                  }}
                >
                  Opprett ordre
                </LoadingButton>
              )}
            </form.Subscribe>
          </Grid2>
        </Grid2>
      </form>
      {orderLink ? (
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Link href={orderLink} target="_blank" rel="noopener noreferrer">Link til ordre</Link>
          </Box>
          <LoadingButton
            save
            buttonProps={{
              sx: { mt: 2 },
              variant: 'contained',
              loading: create24SOOrderMutation.isPending,
              disabled: create24SOOrderMutation.isPending,
              onClick: () => create24SOOrderMutation.mutateAsync(orderLink.split('=')[1]),
            }}
          >
            Opprett 24SO ordre
          </LoadingButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default CreateServiceChecklistForm;
