import {
  Divider, Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import React from 'react';

const MonitorCalendarPage = () => {
  console.log('ok');
  return (
    <Box>
      <Typography variant="h4" gutterBottom>Calendar</Typography>
      <Divider sx={{ mb: 2 }} />
    </Box>
  );
};

export default MonitorCalendarPage;
