import {
  Assessment,
  ChevronLeft, EditNotifications, Email, Event, Home, HomeRepairService, Inventory,
  NotificationImportant, Phone, Policy, Receipt, ReceiptLong, Search, Settings, Sms, Upgrade, Webhook,
} from '@mui/icons-material';
import type { CSSObject, Theme } from '@mui/material';
import {
  Divider, Drawer as MuiDrawer, IconButton, List, ListItem, ListItemButton,
  ListItemIcon, ListItemText, styled, Tooltip,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AccessTypes } from '../../types/index.js';
import CpproUserContext from '../../contexts/CpproUserContext.js';
import type { UserData } from '../../providers/CpproUserProvider.js';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type MonitorSidebarProps = {
  open: boolean;
  handleClose: () => void;
};

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

type SidebarItem = {
  text: string,
  url: string,
  icon: JSX.Element | React.ReactNode | null,
  subItems?: SidebarItem[],
  requiredRole?: AccessTypes.AccessRole,
  requiredRoleLevel?: AccessTypes.AccessRoleLevel,
};

const topItems: SidebarItem[] = [
  {
    text: 'ERP Fakturering',
    url: 'erpInvoicing',
    icon: <Receipt />,
    // requiredRole: AccessTypes.AccessRoles.ERP,
    // requiredRoleLevel: AccessTypes.AccessRoleLevels.WRITE,
  },
  {
    text: 'ERP Oversikt',
    url: 'erpOverview',
    icon: <ReceiptLong />,
  },
  {
    text: 'ERP Verktøy',
    url: 'erpTools',
    icon: <HomeRepairService />,
  },
  {
    text: 'MRP Plukk',
    url: 'mrpPickErrors',
    icon: <Inventory />,
  },
  {
    text: 'CPPro Verktøy',
    url: 'cpproTools',
    icon: <Search />,
  },
  {
    text: 'Hjelpefunksjoner for migrering',
    url: 'migrationHelpers',
    icon: <Upgrade />,
  },
];

const items: SidebarItem[] = [
  {
    text: 'Overview', url: 'overview', icon: <Home />,
  },
  {
    text: 'Email',
    url: 'email',
    icon: <Email />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  {
    text: 'SMS',
    url: 'sms',
    icon: <Sms />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  {
    text: 'Lead API',
    url: 'lead',
    icon: <Phone />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  {
    text: 'ERP 24SO',
    url: 'erp',
    icon: <Assessment />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  {
    text: 'Calendar',
    url: 'calendar',
    icon: <Event />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  {
    text: 'Webhooks',
    url: 'tasks',
    icon: <Webhook />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  {
    text: 'Policies',
    url: 'policies',
    icon: <Policy />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  {
    text: 'Incidents',
    url: 'incidents',
    icon: <NotificationImportant />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.READ,
  },
  // {
  //   text: 'ActivityHelper',
  //   url: 'activityhelper',
  //   icon: null,
  //   subItems: [
  //     { text: 'Test1', url: 'test1', icon: <Event /> },
  //     { text: 'Test2', url: 'test2', icon: <Event /> },
  //   ],
  // },
];

const bottomItems: SidebarItem[] = [
  {
    text: 'Alert Admin',
    url: 'alertAdmin',
    icon: <EditNotifications />,
    requiredRole: AccessTypes.AccessRoles.MONITOR,
    requiredRoleLevel: AccessTypes.AccessRoleLevels.WRITE,
  },
  {
    text: 'Administration',
    url: 'admin',
    icon: <Settings />,
  },
];

const checkSidebarItem = (item: SidebarItem, user: UserData | null) => {
  if (!item.requiredRole || !item.requiredRoleLevel) return true;
  if (item.requiredRoleLevel === 'NONE') return true;

  if (!user?.roles) return false;
  const userRoleLevel = user.roles[item.requiredRole];
  if (!userRoleLevel) return false;

  switch (item.requiredRoleLevel) {
    case AccessTypes.AccessRoleLevels.READ:
      return AccessTypes.ReadArray.includes(userRoleLevel);
    case AccessTypes.AccessRoleLevels.WRITE:
      return AccessTypes.WriteArray.includes(userRoleLevel);
    case AccessTypes.AccessRoleLevels.ADMIN:
      return item.requiredRoleLevel === userRoleLevel;
    default:
      return false;
  }
};

// TODO Add support for subitems^^

const MonitorSidebar = (props: MonitorSidebarProps) => {
  const { open, handleClose } = props;
  const location = useLocation();

  const { user } = useContext(CpproUserContext);

  const actualItems = useMemo(() => items.filter((item) => checkSidebarItem(item, user)), [user]);
  const actualBottomItems = useMemo(() => bottomItems.filter((item) => checkSidebarItem(item, user)), [user]);

  return (
    (
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleClose}>
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* TODO Common component for list! */}
          {topItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <Tooltip title={open ? '' : item.text} placement="right">
                <ListItemButton
                  component={Link}
                  to={item.url}
                  sx={[{
                    minHeight: 48,
                    px: 2.5,
                  }, open ? {
                    justifyContent: 'initial',
                  } : {
                    justifyContent: 'center',
                  }]}
                  selected={location.pathname.endsWith(item.url)}
                >
                  <ListItemIcon sx={[{
                    minWidth: 0,
                    justifyContent: 'center',
                  }, open ? {
                    mr: 3,
                  } : {
                    mr: 'auto',
                  }]}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={[open ? {
                      opacity: 1,
                    } : {
                      opacity: 0,
                    }]}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {actualItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <Tooltip title={open ? '' : item.text} placement="right">
                <ListItemButton
                  component={Link}
                  to={item.url}
                  sx={[{
                    minHeight: 48,
                    px: 2.5,
                  }, open ? {
                    justifyContent: 'initial',
                  } : {
                    justifyContent: 'center',
                  }]}
                  selected={location.pathname.endsWith(item.url)}
                >
                  <ListItemIcon sx={[{
                    minWidth: 0,
                    justifyContent: 'center',
                  }, open ? {
                    mr: 3,
                  } : {
                    mr: 'auto',
                  }]}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={[open ? {
                      opacity: 1,
                    } : {
                      opacity: 0,
                    }]}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {actualBottomItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <Tooltip title={open ? '' : item.text} placement="right">
                <ListItemButton
                  component={Link}
                  to={item.url}
                  sx={[{
                    minHeight: 48,
                    px: 2.5,
                  }, open ? {
                    justifyContent: 'initial',
                  } : {
                    justifyContent: 'center',
                  }]}
                  selected={location.pathname.endsWith(item.url)}
                >
                  <ListItemIcon sx={[{
                    minWidth: 0,
                    justifyContent: 'center',
                  }, open ? {
                    mr: 3,
                  } : {
                    mr: 'auto',
                  }]}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={[open ? {
                      opacity: 1,
                    } : {
                      opacity: 0,
                    }]}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Drawer>
    )
  );
};

export default MonitorSidebar;
