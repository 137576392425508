import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import App from './components/App.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// import '@mui/material-pigment-css/styles.css';
import { createAxiosInstance } from './utils/axios.js';

if (!import.meta.env.VITE_API_URL) {
  // eslint-disable-next-line no-console
  console.error('Missing VITE_API_URL');
}

createAxiosInstance(import.meta.env.VITE_API_URL, axios);

const element = document.getElementById('react');
if (element) {
  const root = createRoot(element);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
